body.info.workshop {
  h1 {
    text-transform: none;
    background-image: url(../images/workshops/banner-texture.png);
    background-size: contain;
    background-blend-mode: soft-light;

    text-shadow: 1px 1px 2px #212121;
    font-family: $extrabold;

    small {
      display: block;
      font-size: 60%;
      text-shadow: none;
      color: #444;

      @include mq($from: tablet) {
        margin-top: -2.2em;
      }
    }
  }

  .inner-wrapper {
    padding: 1rem 2rem;
  }

  .tagLine {
    text-align: center;
    font-family: $bold;
    font-weight: bold;
    padding-bottom: 0.5em;
    border-bottom: 0.1em solid #eee;
    margin-bottom: 2rem;
  }

  .workshop-cols {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    @include mq($until: tablet) {
      flex-direction: column;
    }
  }

  .workshop-main {
    flex-grow: 1;

    @include mq($from: tablet) {
      .workshop-intro + .workshop-cta {
        display: none;
      }
    }
  }

  h2,
  .no-internal-nav {
    text-transform: none;
    border-bottom: none;
    border-top: 1px solid #eae4db;
    margin: 2em 0 1rem;
    padding: 0.5rem 0 0;
  }

  .workshop-sidebar {
    min-width: 25%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .workshop-intro {
    font-size: 115%;

    p {
      margin-bottom: 1rem;
    }
  }

  .workshop-facets {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: stretch;

    li {
      width: 20rem;
      flex-grow: 1;
    }

    strong {
      display: block;
    }
  }

  .workshop-curriculum li {
    list-style: '✅ ';
    margin: 0 0 1rem 1rem;
  }

  .workshop-trainer-cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
  }

  .workshop-trainer-bio {
    width: 20em;
    flex-grow: 1;
    order: 1;
  }

  .workshop-trainer-avatar {
    display: block;
    width: 10rem;
  }

  .workshop-trainer-cols .workshop-trainer-avatar {
    order: 2;
    @include mq($until: tablet) {
      order: 0;
    }
  }

  @include mq($until: tablet) {
    .workshop-cols {
      align-items: stretch;
    }
    .workshop-sidebar {
      .workshop-trainer,
      .workshop-cta {
        display: none;
      }
    }
  }

  .workshop-trainer a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #444;

    span:last-child {
      text-decoration: underline;
    }
  }

  .workshop-related-trainings {
    h3 {
      margin-top: 0;
      border-top: 1px solid #eae4db;
      padding: 0.5rem 0 0;
      text-align: center;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      @include mq($from: tablet) {
        flex-direction: column;
        text-align: center;
      }
      gap: 0.5rem;
    }

    a {
      font-weight: bold;
    }
  }

  .workshop-cta {
    padding-top: 1rem;
    border-top: 1px solid #eae4db;

    text-align: center;

    --workshop-cta-button-bg: #f7df1e;
    --workshop-cta-button-fg: black;

    &.git {
      --workshop-cta-button-bg: #f05033;
      --workshop-cta-button-fg: white;
    }

    &.react {
      --workshop-cta-button-bg: #1eb9d7;
      --workshop-cta-button-fg: white;
    }

    button {
      display: block;
      margin: 0 0 0.5em;
      width: 100%;
      border: none;
      border-radius: 0.2rem;
      background: var(--workshop-cta-button-bg);
      color: var(--workshop-cta-button-fg);
      padding: 0.5em 0.2em 0.7em;
      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Helvetica Neue, Ubuntu, sans-serif;
      font-weight: 500;
      cursor: pointer;

      &:hover,
      &:focus {
        filter: saturate(200%);
      }
    }

    aside {
      background: none;
      color: #444;
    }
    s {
      text-decoration-color: red;
      text-decoration-style: wavy;
      text-decoration-thickness: 0.1rem;
    }
    .discountedPrice {
      font-family: $bold;
      font-weight: bold;
      background-color: rgb(255, 253, 125);
    }
    .remoteInfo {
      font-size: 60%;
      color: #777;
    }
  }

  .workshop-main .workshop-cta {
    margin-top: 2rem;
    .remoteInfo {
      font-size: 70%;
    }
  }
}
