/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
  display: inline-block;
  padding: 0;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:focus,
  &:hover,
  &:active {
    opacity: 0.75;
  }
  &.is-active {
    background-color: $dark-beige;
    opacity: 1;
  }
}

.hamburger-box {
  width: 24px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  &,
  &::before,
  &::after {
    width: 24px;
    height: 3px;
    background-color: $white;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: ' ';
    display: block;
  }
  &::before {
    top: -8px;
    transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in;
  }
  &::after {
    bottom: -8px;
    transition: bottom 0.1s 0.34s ease-in,
      transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .hamburger.is-active & {
    transform: rotate(225deg);
    transition-delay: 0.14s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &,
    &::before,
    &::after {
      background-color: $dark-grey;
    }
    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.1s ease-out, opacity 0.1s 0.14s ease-out;
    }
    &::after {
      bottom: 0;
      margin-left: 0;
      width: 24px;
      transform: rotate(-90deg);
      transition: bottom 0.1s ease-out,
        transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
