// ---------------- //
// --- TRAINING --- //
// ---------------- //

// https://developer.paciellogroup.com/blog/2012/05/html5-accessibility-chops-hidden-and-aria-hidden/
.offscreen {
  clip-path: inset(100%);
  clip: rect(1px 1px 1px 1px); /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap; /* added line */
  width: 1px;
}

#main {
  .moreInfoPad {
    display: flex;
    justify-content: flex-end;
    margin: 1em 0 0;
  }

  .moreInfoSheet {
    background: $dark-beige;
    color: $dark-grey;
    font-size: 1rem;
    padding: 0.3rem 1rem 1rem;
    border-radius: 1rem 0;

    .heading {
      font-weight: bold;
      font-family: $bold;
      margin: 0 0 0.4rem;
    }

    .resource {
      margin: 0 0 0.4rem;

      &::before {
        content: '📃 ';
      }
    }

    a {
      color: $brown;

      &:hover,
      &:focus {
        color: $dark-blue;
        text-decoration: underline;
      }
    }
  }
}

.training {
  // Sections
  .inner-wrapper section:nth-child(even) {
    background-color: $dark-white;
  }

  main > aside.details {
    display: none;
  }
  .tab > aside.details {
    display: block;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin: 0 0 2rem;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .remoteBadge {
    display: inline-block;
    position: relative;
    margin-bottom: 1em;
    padding: 0.1em 0.4em;
    background-color: $blue;
    color: $white;
    font-family: $bold;

    &::before {
      content: '► ';
    }

    a {
      color: yellow;
    }
  }

  #nextSessions,
  .details {
    p.ratings {
      margin-bottom: 1rem;
      a {
        color: #2f2611;
        text-decoration: none;
        &:hover,
        &:focus {
          color: #1eb9d7;
        }
      }
    }

    .remoteSessionLabel,
    .discountNotice {
      display: inline-block;
      margin-left: 0.6em;
      padding: 0.1em 0.4em;
      font-size: 85%;
      color: $blue;
      background-color: $white;
      font-family: $bold;
    }
  }

  .details .remoteSessionLabel {
    margin-left: 0;
    color: black;
    // background-color: transparent;
    background-color: $grey;
    font-size: 0.8rem;
  }

  .cta {
    text-transform: uppercase;
    &::before,
    &::after {
      content: normal;
    }
    button {
      text-transform: inherit;
    }
  }
  ol {
    list-style: none;
    padding-left: 0;
    ul {
      list-style-type: disc;
    }
  }
}

// Training summary
.summary {
  & > ul {
    padding-left: 0 !important;
    font-family: $bold;
    list-style: none;
    li {
      &::before {
        content: ' ';
        display: inline-block;
        background-image: $sprite;
        background-repeat: no-repeat;
      }
    }
  }
}

// Training details
.details {
  color: $dark-grey;
  background-color: $light-beige;
  // First part
  .teaser {
    span {
      color: $blue;
    }
  }
  span {
    display: block;
  }

  // Second part
  .inter {
    @include sprite-background(false, false);
    background-color: $dark-beige;
    &::before {
      top: -18px;
      right: 10px;
      border-radius: 35px;
      background-color: $blue;
    }
    h2 {
      margin: 0;
      border-bottom: 0;
      padding-top: 0;
      font-size: inherit;
      line-height: lh(28, 18);
      text-transform: none;
    }

    ul {
      padding: 0;
      li {
        list-style: none;

        a {
          color: $dark-blue;
        }
      }
    }

    .see {
      font-size: 0.9rem;
      text-align: center;
      display: block;
      margin: 1rem;
    }
  }
  p.stacker {
    height: 0;
    margin: 0;
  }
  .intra {
    font-size: 80%;

    p {
      text-align: center;
    }

    .cta a {
      background: #c8b9a2;
      width: auto;
      padding: 1em 4em;
    }
  }
  ul {
    margin-bottom: 0;
  }
  .see {
    color: $orange;
  }
}

// Program
#program {
  counter-reset: program;
  h3::before {
    counter-increment: program;
    content: counter(program) '. ';
    color: $blue;
  }
}

#intra {
  .ctaWrapper {
    text-align: center;
  }
  .cta a {
    width: auto;
    padding: 1em;
  }
}

// Next trainings
#nextSessions {
  color: $white;
  background-color: $blue;
  h2 {
    border-bottom-color: rgba($white, 0.25);
    &:not(:first-of-type) {
      margin-right: 0;
    }
  }
  a {
    font-family: $bold;
    color: $white;
  }

  .session-list {
    a {
      font-family: $bold;
      color: $white;
      background: $orange;
      padding: 0.2rem 0.5rem;
      display: inline-block;
      border-radius: 0.4rem;
      text-decoration: none;
      // font-size: 80%;
      &:hover,
      &:focus {
        text-decoration: underline;
      }
      &:active {
        background: $dark-orange;
      }
    }

    .remoteSessionLabel,
    .discountNotice {
      display: inline-block;
      margin: 0;
    }

    li {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-wrap: wrap;

      border: 1px solid rgba(255, 255, 255, 0.25);
      padding: 0.5rem;
      border-radius: 0.4rem;
      margin-bottom: 1rem;

      & > div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.25rem;

        & > p {
          margin: 0;

          &.dates {
            font-weight: bold;
          }
          &.availabilityNotice {
            display: flex;
            gap: 0.25rem;
            align-items: center;
          }
          &:not(.dates) {
            font-size: 80%;
          }
        }
      }
    }
  }

  .intra {
    text-align: center;

    p {
      text-align: left;
    }

    .cta a {
      width: auto;
      padding: 1em;
      background: #147b90;
    }
  }

  // Attention icon
  img {
    margin-left: 2px;
    vertical-align: top;
  }
  .cta span {
    display: inline;
    font-size: inherit;
  }
}
.table-wrapper {
  // Avoid pseudo-element overlfow
  overflow: hidden;
  background-color: $dark-beige;
  p {
    position: relative;
    color: $dark-grey;
    background-color: $light-beige;
    // Background arrows
    &::before,
    &::after {
      content: '';
      position: absolute;
      border-style: solid;
      border-color: transparent;
    }
    span {
      display: block;
      color: $blue;
    }
  }
}

// Logisitic (address & transport)
#resources {
  address {
    background-color: $light-beige;
  }
}
.transport {
  list-style: none;
  background-color: $dark-beige;
  img + span {
    margin-left: 5px;
  }
}
.or {
  font-family: $regular;
  color: $brown;
  &::after {
    content: normal;
  }
}
// Schedule
.schedule span {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  font-family: $bold;
  color: $blue;
  &::after {
    content: '\02013';
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -1px;
    font-family: $regular;
    font-weight: normal;
    color: $dark-grey;
  }
}

// Popup gallery
.popup-gallery {
  list-style: none;
  .training & {
    padding-left: 0;
  }
  a {
    @include sprite-background(false, false, after);
    display: block;
    // Popup icon
    &::after {
      content: normal;
      left: 50%;
      top: 50%;
      margin-top: -25px;
      margin-left: -25px;
      background-color: $orange;
      box-shadow: 0 0 15px 5px rgba($dark-grey, 0.45);
      border-radius: 25px;
    }
    #{$link-states} {
      outline: 1px solid $orange;
      &::after {
        content: ' ';
      }
    }
  }
  img {
    vertical-align: bottom;
  }
}

// Trainer
#trainer {
  h3 {
    font-size: inherit;
  }

  ul.credentials {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    gap: 1em;
    font-size: 80%;
    font-family: $bold;

    a {
      display: flex;
      align-items: center;
      gap: 0.3em;
    }

    .icon {
      display: inline-block;
      width: 1.5em;
      height: 1.5em;
      stroke-width: 0;
      stroke: currentColor;
      fill: currentColor;
    }
  }
}

#quotation-request {
  background-color: $light-beige;

  form {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;

    .fields {
      display: flex;
      justify-content: space-between;

      p {
        width: 45%;
      }
    }

    .cta {
      margin-bottom: 0;
    }

    @include mq($until: tablet) {
      .fields {
        display: block;
        p {
          width: 100%;
          margin-bottom: 1rem;
        }
      }

      .cta {
        margin: 2rem 0;
      }
    }
  }
}

// Testimony

.training .inner-wrapper #testimony {
  background-color: transparent;
}

#testimony {
  .testimony:not(.m-scooch-first-is-large) .m-scooch-item {
    &:nth-child(odd) {
      padding-left: 0;
    }
    &:nth-child(even) {
      padding-right: 0;
    }
  }
  .m-scooch-item p {
    margin: 0;
  }
  .m-scooch-item-large {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  .testimony.m-scooch-first-is-large .m-scooch-item {
    &:nth-child(even) {
      padding-left: 0;
    }
    &:nth-child(odd) {
      padding-right: 0;
    }
  }
}

#ratings {
  .rating-details {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
    gap: 1rem;
  }
  .rating-component {
    min-width: 8rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1;

    img {
      height: 7rem;
      max-width: 100%;
    }

    .value {
      font-size: 130%;
      font-weight: bold;
      font-family: $extrabold;
      color: $blue;
      margin-bottom: 0.3rem;
    }

    .label {
      font-size: 90%;
      color: $brown;
    }
  }
  .employabilityRating {
    font-size: 90%;
    color: $brown;
    text-align: center;
    a {
      color: $blue;
      text-decoration: underline;
      cursor: pointer;
      &:hover,
      &:focus {
        color: $orange;
      }
    }
  }
}

// Quotation
#quotation {
  margin: 0 auto;
  border-bottom-style: solid;
  border-bottom-color: $blue;
  background-color: $white;
  h2 {
    border-bottom: 0;
    padding-top: 0;
    text-align: center;
    color: $white;
    background-color: $blue;
  }
  /*
  @TODO : Check if it's still useful
  .cta button {
    position: relative;
    width: auto;
    &::before, &::after {
      content: '';
      position: absolute;
    }
    &::after {
      position: absolute;
      top: 50%;
      background-image: $sprite;
      background-repeat: no-repeat;
    }
    &::before {
      top: 0;
      bottom: 0;
      left: 0;
      background-color: $dark-orange;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }
*/
  // Custom select (list)
  .training .nice-select .list {
    left: auto;
    right: 0;
  }

  &.inplace {
    padding-bottom: 0;
    table {
      margin-bottom: 0;
      color: $dark-grey;
    }
  }
}

// Training tab navigation (base styles, to be overridden by viewport size)

.training .inner-wrapper {
  & > nav {
    position: sticky;
    z-index: 1;
    background-color: $white;
    top: -1px; /* To enable intersection observer detection */
    height: em(53, 18);
    padding-bottom: 1em;

    @include mq($until: tablet) {
      height: em(100, 18);
      & > ul > li {
        flex-basis: calc(
          767px / 5
        ); /* Reasonable wrapping across non-desktop viewports */
      }
    }

    @include mq($until: 614px) {
      height: em(150, 18);
    }

    @include mq($until: 460px) {
      height: em(200, 18);
    }
  }

  & > nav > ul {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0;
    padding: 0;

    & > li {
      flex-grow: 1;
      text-align: center;

      & > a {
        display: block;
        padding: 0.5em;
        text-decoration: none;
        border-block-end: 0.2em solid #f8f8f2;

        &.active {
          border-color: orange;
          background-color: #fbfaf9;
          font-family: 'open_sanssemibold';
        }
      }
    }
  }

  & > main {
    display: flex;
    align-items: flex-start;
    z-index: 0;

    & > aside {
      order: 2;
      width: em(370, 18);
      position: sticky;
      top: calc(49px + 1em);

      & div {
        display: table-cell;
      }
    }

    & div.tab {
      margin: 0 em(18, 18) em(18, 18);
      isolation: isolate;

      display: none;

      @include mq($from: desktop) {
        padding-right: em(30, 18);
        width: em(740, 18);
      }

      &:target {
        display: block;
      }
    }

    #testimony li.m-scooch-item {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

// --- Mobile --- //

@include mq($until: tablet) {
  #nextSessions .session-list li {
    flex-direction: column;
    align-items: center;

    & > div {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }

    p.dates {
      width: 100%;
      text-align: center;
    }
  }

  .training,
  .info {
    .inner-wrapper {
      > header {
        padding-bottom: 0;
      }
      // Sections
      section {
        overflow: hidden; // Formatting context
        padding: 0 em(20, 12) em(40, 12);
      }
    }
    h2 {
      margin-right: 0;
      margin-left: 0;
    }
    ul {
      padding-left: em(12, 12);
    }
    .cta {
      margin-bottom: em(24, 12);
      a,
      button {
        padding: em(12, 12) 0;
        line-height: lh(16, 12);
      }
    }
    // Code
    code,
    tt {
      line-height: lh(16, 12);
    }
  }
  // Training summary
  .summary {
    & > ul li {
      &::before {
        margin-right: 5px;
        width: em(14, 12);
        height: em(12, 12);
        background-position: em(-842, 12) em(-435, 12);
      }
    }
  }
  // Training details
  .details {
    margin: em(20, 12) em(-20, 12) 0;
    padding: em(20, 12) 0 0;
    p,
    div {
      padding: 0 em(20, 12);
    }
    // Second part
    div {
      padding-top: em(20, 12);
      // Background image
      &::before {
        right: 20px;
        width: em(70, 12);
        height: em(70, 12);
        background-position: em(-240, 12) em(-217, 12);
      }
    }
    h2 {
      padding-right: em(84, 12);
      padding-bottom: 0;
      text-align: left;
    }
    ul {
      margin: em(10, 12) 0;
    }
    .cta {
      // margin: em(20, 12) 0 0;
      margin: 1rem 0 0;
      padding-bottom: em(24, 12);
    }
  }
  // First part (training details)
  .teaser {
    line-height: lh(20, 12);
    span {
      font-size: em(20, 12);
      line-height: lh(30, 20);
    }
  }
  // Internal nav
  #internal {
    display: none;
  }
  // Goal & Program
  #goal,
  #program {
    ul li {
      padding-bottom: em(6, 12);
    }
  }
  // Program
  #program {
    ol ul {
      margin-bottom: em(25, 12);
      ul {
        margin-bottom: 0;
      }
    }
    h3 {
      margin-bottom: em(10, 15);
    }
  }
  // Next training
  #nextSessions {
    padding-bottom: em(30, 12);
    // Attention icon
    img {
      width: 21px;
      height: 21px;
    }
  }
  .table-wrapper {
    p {
      padding-top: em(20, 12);
      text-align: center;
      line-height: lh(20, 12);
      span {
        font-size: em(20, 12);
        line-height: lh(30, 20);
      }
      // Useful to pseudo-element
      border-bottom: 10px solid #efebe4; // Hexadecimal code match rgba value
      // Bottom arrows
      &::before,
      &::after {
        left: 50%;
        margin-left: -360px;
        border-width: 360px;
        border-top-width: 16px;
      }
      &::before {
        top: calc(100% + 10px);
        border-top-color: rgba($light-beige, 0.5);
      }
      &::after {
        top: 100%;
        border-top-color: $light-beige;
      }
    }
    .cta {
      display: block;
      margin: em(40, 12) 0 em(20, 12);
    }
  }
  // Logisitic
  // Address & transport
  #resources {
    > div {
      margin: em(-10, 12) 0 em(10, 12);
    }
    address,
    .transport {
      padding: em(15, 12) em(10, 12);
    }
    address {
      font-size: em(14, 12);
      line-height: lh(20, 14);
    }
    .transport {
      margin-bottom: 0;
      line-height: lh(30, 12);
    }
    // Icons
    .round,
    .rectangle {
      width: 20px;
      height: auto;
    }
    .round {
      vertical-align: -5px;
    }
    .rectangle {
      vertical-align: -1px;
    }
  }
  // Schedule
  .schedule span {
    min-width: em(48, 12);
  }
  // Gallery
  .popup-gallery {
    li {
      margin-bottom: em(20, 12);
      &:last-child {
        margin-bottom: 0;
      }
    }
    a::after {
      width: em(50, 12);
      height: em(50, 12);
      background-position: em(-312, 12) em(-196, 12);
    }
    img {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
  // Trainer
  #trainer {
    > div {
      margin-top: em(20, 12);
    }
    img {
      margin: 0 auto em(10, 12);
      display: block;
    }
    h3 {
      margin-bottom: em(5, 15);
      text-align: center;
    }
  }
  // Testimony
  #testimony {
    h2 {
      margin-top: 25px;
      border-bottom: 0;
    }
  }
  // Quotation
  #quotation {
    border-bottom-width: em(5, 12);
    padding-bottom: em(20, 12);
    h2 {
      margin-top: 0;
      padding-top: em(10, 17);
    }
    // Select above the form
    #form-training + .nice-select {
      display: block;
      margin-top: em(5, 12);
      width: 100%;
    }
    .list {
      width: inherit;
    }
    form {
      padding: 0 em(20, 12);
    }
    table {
      margin: em(15, 12) 0 em(20, 12);
    }
    th,
    td {
      padding: em(5, 12) em(12, 12);
      white-space: nowrap;
    }
    // Trick to bypass border-collapse effect
    tfoot::before {
      height: em(5, 12);
    }
    // Allow table scroll on mobile
    .table-overflow {
      overflow: hidden;
      width: 100%;
      > div {
        overflow-x: scroll;
      }
    }
    .cta {
      margin: em(20, 12) 0;
      button {
        font-size: em(15, 12);
        padding-right: em(20, 15);
        padding-left: em(55, 15);
        // Background image
        &::before {
          width: 41px;
        }
        &::after {
          margin-top: -5px;
          left: 13px;
          width: em(15, 15);
          height: em(12, 15);
          background-position: em(-535, 15) em(-625, 15);
        }
      }
    }
    p {
      line-height: lh(18, 12);
    }
  }
}

// --- Tablet & Desktop --- //

@include mq($from: tablet) {
  #nextSessions .session-list {
    width: 38rem;
  }

  // Main title
  .git-total,
  .git-total-3j,
  .node-js,
  .web-apps-modernes,
  .es-total,
  .es-total-3j,
  .architecture-css,
  .webpack {
    section > h1::before {
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  .training {
    main > aside.details {
      display: block;
      // inter .cta a {
      //   width: 100%;
      // }
    }
    .tab > aside.details {
      display: none;
    }
  }

  // Section background images
  .inner-wrapper section {
    @include sprite-background(false, false);
    > div,
    > h2,
    > p,
    > ul,
    > ol,
    .m-scocch {
      position: relative; // Put elements on top of background images
    }
  }
  .training {
    h2 {
      text-align: left;
      margin-left: 0;
    }
  }
  // Training summary
  .summary {
    & > ul {
      column-count: 2;
      li::before {
        vertical-align: -1px;
      }
    }
  }
  // Training details
  .details {
    .cta {
      display: block;
    }
  }
  // Next training
  .table-wrapper {
    display: table;
    width: 100%;
    p,
    > span {
      display: table-cell;
      vertical-align: middle;
    }
    p {
      width: 50%;
      // Right arrows
      &::before,
      &::after {
        top: 0;
      }
      &::before {
        left: calc(100% + 10px);
        border-left-color: rgba($light-beige, 0.5);
      }
      &::after {
        left: 100%;
        border-left-color: $light-beige;
      }
    }
    // Useful to pseudo-element
    .cta {
      border-left: 10px solid rgba($light-beige, 0.5);
    }
  }
  // Logisitic
  #resources {
    > div {
      display: flex;
      align-items: stretch;
    }
    .transport,
    address {
      box-sizing: border-box;
      width: 50%;
    }
    address {
      @include sprite-background(false, false);
      order: 1;
      &::before {
        top: 0;
        left: 0;
      }
    }
    .transport {
      order: 2;
      margin-bottom: 0;
    }
    // Icons
    .round {
      vertical-align: -7px;
    }
    .rectangle {
      vertical-align: -2px;
    }
  }
  // Gallery
  .popup-gallery {
    display: flex;
    flex-wrap: wrap;
    li {
      box-sizing: border-box;
      width: 50%;
    }
  }
  // Trainer
  #trainer {
    img {
      float: left;
    }
    > div {
      clear: left;
    }
    div div {
      overflow: hidden; // Formatting context
    }
  }
  // Testimony
  #testimony {
    ul {
      padding-left: 0;
    }
    h2 {
      margin-bottom: 0;
    }
  }
  // Internal nav
  #internal {
    position: absolute;
    z-index: 1;
    background-color: $light-beige;
    ul {
      list-style: none;
      padding-left: 0;
    }
    li {
      border-bottom: 1px solid $dark-beige;
      &:first-child {
        border-top: 1px solid $dark-beige;
      }
    }
    a {
      display: block;
      text-decoration: none;
      color: $dark-grey;
      transition: background-color 0.75s ease, color 0.75s ease;
      &:hover,
      &:focus {
        color: $white;
        background: $blue;
      }
    }
    // JS behavior
    &.is-sticky {
      position: fixed;
      top: 0;
      z-index: 3;
    }
    // Active link
    li.is-active {
      a {
        position: relative;
        font-family: $bold;
        color: $white;
        background: $blue;
        &::after {
          content: '\000BB';
          display: inline-block;
          margin-left: 10px;
          font-family: inherit;
          font-weight: normal;
        }
        code,
        tt {
          color: $white;
        }
      }
    }
  }
  // Quotation
  #quotation {
    h2 {
      margin-right: 0;
    }
    .cta button {
      font-size: rem(18);
      padding-right: em(30, 18);
      padding-left: em(65, 18);
      // Background image
      &::before {
        width: 54px;
      }
      &::after {
        margin-top: -11px;
        left: 17px;
        width: em(24, 18);
        height: em(20, 18);
        background-position: em(-482, 18) em(-622, 18);
      }
    }
  }
}

// --- Tablet --- //

@include mq(tablet, desktop) {
  // Main title
  .git-total,
  .git-total-3j,
  .web-apps-modernes,
  .node-js,
  .es-total,
  .es-total-3j,
  .architecture-css,
  .webpack {
    section > h1::before {
      width: em(258, 40);
    }
  }
  // Git
  .git-total,
  .git-total-3j {
    section > h1::before {
      background-position: em(-630, 30) em(-1089, 30);
    }
  }
  // WAM
  .web-apps-modernes section > h1::before {
    background-position: em(-960, 30) em(-627, 30);
  }
  // Node
  .node-js section > h1::before {
    background-position: em(-918, 30) em(-1089, 30);
  }
  // ES
  .es-total,
  .es-total-3j {
    section > h1::before {
      background-position: em(-1260, 30) em(-627, 30);
    }
  }
  // CSS Architecture
  .architecture-css section > h1::before {
    background-position: em(-1485, 30) em(-1089, 30);
  }
  // Webpack
  .webpack section > h1::before {
    background-position: em(-1200, 30) em(-1089, 30);
  }
  .training,
  .info {
    .inner-wrapper {
      // Sections
      section {
        padding: 0 em(20, 14) em(48, 14) em(20, 14);

        img {
          max-width: 80%;
          display: block;
          margin: 0 auto;
        }
      }
    }
    h2 {
      margin: 0 em(170, 20) em(12, 20) 0;
      padding-top: em(33, 20);
    }
    ul {
      padding-left: em(14, 14);
    }
    .cta {
      a,
      button {
        padding: em(14, 14) 0;
        line-height: lh(20, 14);
        width: em(238, 14);
      }
    }
    // Code
    code,
    tt {
      line-height: lh(27, 14);
    }
  }
  // Training summary
  .summary > :not(.details) {
    ul {
      line-height: lh(21, 14);
    }
    li {
      padding-top: 0.25em;
      padding-bottom: 0.25em;
      &::before {
        margin-right: 7px;
        width: em(20, 14);
        height: em(14, 14);
        background-position: em(-813, 14) em(-435, 14);
      }
    }
  }
  // Training details
  .details {
    padding: em(24, 14) 0 0;
    p,
    div {
      padding: 0 em(20, 14);
    }
    // Second part
    div {
      padding-top: em(24, 14);
      // Background image
      &::before {
        right: 10px;
        width: em(70, 14);
        height: em(70, 14);
        background-position: em(-240, 14) em(-217, 14);
      }
    }
    h2 {
      padding-right: em(70, 14);
    }
    .cta {
      margin: em(24, 14) 0;
    }
  }
  // First part (training details)
  .teaser {
    line-height: lh(21, 14);
    span {
      font-size: em(20, 14);
      line-height: lh(28, 20);
    }
  }
  // Internal nav
  #internal {
    font-size: 76%;
    margin-left: 20px;
    padding: em(36, 14) 0;
    width: em(275, 14 * 0.76);
    line-height: lh(36, 14);
    a {
      padding-left: em(20, 14);
    }
  }
  // Goal & Program
  #goal,
  #program {
    ul li {
      padding-bottom: em(6, 14);
    }
  }
  #goal {
    // Background image
    &::before {
      top: 10px;
      right: 15px;
      width: em(214, 14);
      height: em(142, 14);
      background-position: em(-251, 14) em(-495, 14);
    }
  }

  // Evaluation
  #evaluation::before {
    top: 15px;
    right: 30px;
    width: em(139, 14);
    height: em(169, 14);
    background-position: em(-941, 14) em(-413, 14);
  }

  // Program
  #program {
    // Background image
    &::before {
      top: 15px;
      right: 30px;
      width: em(139, 14);
      height: em(169, 14);
      background-position: em(-941, 14) em(-413, 14);
    }
    ol {
      ul {
        margin-bottom: em(30, 14);
      }
    }
    // Ellipsis
    h3 {
      margin-bottom: em(12, 17);
    }
  }
  // Next training
  #nextSessions {
    padding-bottom: em(30, 14);
    // // Background image
    // &::before {
    //   top: 35px;
    //   right: 20px;
    //   width: em(100, 14);
    //   height: em(103, 14);
    //   background-position: em(-474, 14) em(-491, 14);
    // }
    > ul {
      li:first-child {
        margin-right: em(
          105,
          14
        ); // Avoid conflict between text & background image
      }
    }
    // Attention icon
    img {
      width: 23px;
      height: 23px;
    }
  }
  .table-wrapper {
    p,
    .cta {
      padding: em(21, 14) em(30, 14);
    }
    p {
      // Right arrows
      &::before,
      &::after {
        top: 50%;
        margin-top: -57px;
        border-width: 57px;
        border-left-width: 16px;
      }
      span {
        font-size: em(20, 14);
        line-height: lh(24, 20);
      }
    }
    // Useful to pseudo-element
    .cta {
      padding-left: em(45, 18);
    }
  }
  // Audience
  #audience,
  #prerequisites {
    // Background image
    &::before {
      top: 15px;
      right: 20px;
      width: em(141, 14);
      height: em(106, 14);
      background-position: em(-610, 14) em(-458, 14);
    }
  }
  // Logisitic
  #resources {
    // Background image
    &::before {
      top: 0;
      right: 10px;
      width: em(237, 14);
      height: em(118, 14);
      background-position: 0 em(-245, 14);
    }
    // Address & transport
    > div {
      margin: em(-12, 14) 0 em(12, 14);
    }
    address {
      padding: em(61, 16) 0 0 em(60, 16);
      font-size: em(16, 14);
      line-height: lh(24, 16);
      &::before {
        width: em(63, 16);
        height: em(90, 16);
        background-position: em(-180, 16) em(-518, 16);
      }
    }
  }
  .transport {
    padding: em(18, 14) 0 em(18, 14) em(20, 14);
    line-height: lh(35, 14);
  }
  // Schedule
  .schedule span {
    min-width: em(56, 14);
  }
  // Gallery
  .popup-gallery {
    li {
      &:nth-child(odd) {
        padding-right: em(10, 14);
      }
      &:nth-child(even) {
        padding-left: em(10, 14);
      }
      &:nth-child(n + 3) {
        margin-top: em(24, 14);
      }
    }
    a::after {
      width: em(50, 14);
      height: em(50, 14);
      background-position: em(-312, 14) em(-196, 14);
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  // Trainer
  #trainer {
    // Background image
    &::before {
      top: 20px;
      right: 20px;
      width: em(158, 14);
      height: em(101, 14);
      background-position: em(-771, 14) em(-458, 14);
    }
    > div {
      padding-top: em(6, 14);
      &:last-child {
        padding-top: em(36, 14);
      }
    }
    img {
      margin-right: em(20, 14);
      width: 130px;
      height: auto;
    }
    h3 {
      line-height: lh(24, 14);
    }
  }
  // Testimony
  #testimony {
    // Background image
    &::before {
      top: 0;
      right: 25px;
      width: em(198, 14);
      height: em(107, 14);
      background-position: 0 em(-400, 14);
    }
    img {
      width: 75px;
      height: auto;
    }
  }
  // Quotation
  .training .mfp-content {
    width: 65%;
  }
  #quotation {
    border-bottom-width: em(7, 14);
    padding-bottom: em(24, 14);
    form {
      padding: 0 em(20, 14);
    }
    table {
      margin: em(18, 14) 0 em(24, 14);
    }
    th,
    td {
      padding: em(6, 14) em(14, 14);
    }
    // Trick to bypass border-collapse effect
    tfoot::before {
      height: em(6, 14);
    }
    .cta {
      margin: em(24, 14) 0;
    }
    p {
      line-height: lh(21, 14);
    }
    th,
    td {
      &:first-child {
        width: em(168, 14);
      }
    }
    label {
      width: em(
        192,
        14
      ); // th & td:first-child - 4px (display: inline-blok white space)
    }
  }
}

// --- Tablet internal down --- //

@include mq(tablet, tablet_upper_boundary) {
  // Next training
  .table-wrapper {
    > p,
    .cta {
      padding: em(12, 14) em(10, 14);
    }

    .cta {
      span {
        display: block;
      }
      a {
        width: em(125, 14);
      }
    }
  }
  // Logisitic
  #resources {
    > div {
      flex-direction: column;
    }
    .transport,
    address {
      width: 100%;
    }
    address,
    .transport {
      order: 0;
    }
    address {
      padding-bottom: em(30, 18);
    }
  }
  // Testimony
  #testimony {
    display: block;
    li {
      padding-right: 0;
      padding-left: 0;
      width: 100%;
    }
  }
  // Quotation
  .training .mfp-content {
    width: 85%;
  }
}

// --- Desktop --- //

@include mq($from: desktop) {
  // Main title
  .git-total,
  .git-total-3j,
  .web-apps-modernes,
  .node-js,
  .es-total,
  .es-total-3j,
  .architecture-css,
  .webpack {
    section > h1::before {
      width: em(344, 40);
    }
  }

  // Git
  .git-total,
  .git-total-3j {
    section > h1::before {
      background-position: 0 em(-1310, 40);
    }
  }
  // WAM
  .web-apps-modernes section > h1::before {
    background-position: em(-366, 40) em(-1312, 40);
  }
  // Node
  .node-js section > h1::before {
    background-position: em(-730, 40) em(-1312, 40);
  }
  // ES
  .es-total,
  .es-total-3j {
    section > h1::before {
      background-position: em(-1100, 40) em(-842, 40);
    }
  }
  // CSS Architecture
  .architecture-css section > h1::before {
    background-position: em(-1460, 40) em(-1312, 40);
  }
  // Webpack
  .webpack section > h1::before {
    background-position: em(-1100, 40) em(-1312, 40);
  }
  .training {
    .inner-wrapper {
      // Sections
      div:not(.tab) > section {
        padding: 0 em(30, 18) em(56, 18); // em(430, 18);
      }
    }
    h2 {
      padding-top: em(32, 26);
    }
    .tab h2 {
      margin: 0 em(170, 26) em(14, 26) 0;
    }
    // We need it slightly longer, butchering the "official" text isn't an
    // option, nor is line-wrapping.
    #registration h2 {
      margin-right: em(160, 26);
    }
    ul {
      padding-left: em(18, 18);
    }
    .cta {
      a,
      button {
        padding: em(18, 18) 0;
        line-height: lh(20, 18);
        width: em(310, 18);
      }
    }
    // Code
    code,
    tt {
      line-height: lh(30, 18);
    }
  }
  // Training summary
  .summary > :not(.details) {
    ul {
      line-height: lh(35, 18);
    }
    li::before {
      margin-right: 10px;
      width: em(22, 18);
      height: em(16, 18);
      background-position: em(-775, 18) em(-433, 18);
    }
  }
  // Training details
  .details {
    padding-top: em(28, 18);
    p,
    div {
      padding: 0 em(30, 18);
    }
    // Second part
    div {
      padding-top: em(28, 18);
      &::before {
        width: em(70, 18);
        height: em(70, 18);
        background-position: em(-240, 18) em(-217, 18);
      }
    }
    h2 {
      padding-right: em(63, 18);
    }
    .cta {
      margin: em(28, 18) 0;
    }
  }
  // First part (training details)
  .teaser {
    line-height: lh(24, 18);
    span {
      font-size: em(26, 18);
      line-height: lh(33, 26);
    }
  }
  // Internal nav
  #internal {
    font-size: 76%;
    margin-left: 30px;
    padding: em(42, 18) 0;
    width: em(340, 18 * 0.76);
    line-height: lh(40, 18);
    a {
      padding-left: em(30, 18);
    }
  }
  // Goal & Program
  #goal,
  #program {
    ul li {
      padding-bottom: em(7, 18);
    }
  }
  #goal {
    // Background image
    &::before {
      top: 10px;
      right: 10px;
      width: em(214, 18);
      height: em(142, 18);
      background-position: em(-251, 18) em(-495, 18);
    }
  }
  // Evaluation
  #evaluation::before {
    top: 15px;
    right: 35px;
    width: em(139, 18);
    height: em(169, 18);
    background-position: em(-941, 18) em(-413, 18);
  }

  // Program
  #program {
    // Background image
    &::before {
      top: 15px;
      right: 35px;
      width: em(139, 18);
      height: em(169, 18);
      background-position: em(-941, 18) em(-413, 18);
    }
    ol ul {
      margin-bottom: em(35, 18);
    }
    // Ellipsis
    h3 {
      margin-bottom: em(14, 22);
    }
  }
  // Next training
  #nextSessions {
    padding-bottom: em(35, 18);
    // // Background image
    // &::before {
    //   top: 40px;
    //   right: 60px;
    //   width: em(100, 18);
    //   height: em(103, 18);
    //   background-position: em(-474, 18) em(-491, 18);
    // }
  }
  .table-wrapper {
    p,
    .cta {
      padding: em(21, 18) em(30, 18);
    }
    p {
      // Right arrows
      &::before,
      &::after {
        border-width: 63px;
        border-left-width: 20px;
      }
      span {
        font-size: em(26, 18);
        line-height: lh(28, 26);
      }
    }
    // Useful to pseudo-element
    .cta {
      border-left: 10px solid rgba($light-beige, 0.5);
      padding-left: em(45, 18);
    }
  }
  // Audience
  #audience,
  #prerequisites {
    // Background image
    &::before {
      top: 15px;
      right: 30px;
      width: em(141, 18);
      height: em(106, 18);
      background-position: em(-610, 18) em(-458, 18);
    }
  }
  // Logisitic
  #resources {
    // Background image
    &::before {
      top: 0;
      right: 10px;
      width: em(237, 18);
      height: em(118, 18);
      background-position: 0 em(-245, 18);
    }
    // Address & transport
    > div {
      margin: em(-14, 18) 0 em(14, 18);
    }
    address {
      padding: em(59, 18) 0 0 em(60, 18);
      &::before {
        width: em(60, 18);
        height: em(90, 18);
        background-position: em(-180, 18) em(-518, 18);
      }
      p {
        margin-bottom: em(21, 18);
      }
    }
  }
  .transport {
    padding: em(21, 14) 0 em(21, 14) em(30, 14);
    font-size: em(14, 18);
    line-height: lh(35, 14);
  }
  // Schedule
  .schedule span {
    min-width: em(72, 18);
  }
  // Gallery
  .popup-gallery {
    li {
      &:nth-child(odd) {
        padding-right: em(15, 18);
      }
      &:nth-child(even) {
        padding-left: em(15, 18);
      }
      &:nth-child(n + 3) {
        margin-top: em(28, 18);
      }
    }
    a {
      &::after {
        width: em(50, 18);
        height: em(50, 18);
        background-position: em(-312, 18) em(-196, 18);
      }
    }
  }
  // Trainer
  #trainer {
    // Background image
    &::before {
      top: 30px;
      right: 30px;
      width: em(158, 18);
      height: em(101, 18);
      background-position: em(-771, 18) em(-458, 18);
    }
    > div {
      padding-top: em(21, 18);
      padding-bottom: em(21, 18);
      border-bottom: 1px solid #e9e3da;
      &:first-child {
        padding-top: em(7, 18);
      }
      &:last-child {
        border-bottom: 0;
      }
    }
    img {
      margin-right: em(30, 18);
    }
    h3 {
      line-height: lh(28, 18);
    }
  }
  // Testimony
  #testimony {
    // Background image
    &::before {
      top: 0;
      right: 25px;
      width: em(198, 18);
      height: em(117, 18);
      background-position: 0 em(-390, 18);
    }

    .has-border {
      // border-radius: 40px;
      border-radius: 50%;
    }

    blockquote {
      font-size: em(14, 18);
      line-height: lh(24, 14);
    }
  }
  // Quotation
  .training .mfp-content {
    width: 55%;
  }
  #quotation {
    border-bottom-width: em(9, 18);
    padding-bottom: em(28, 18);
    form {
      font-size: em(14, 18);
      padding: 0 em(30, 18);
    }
    table {
      margin: em(21, 14) 0 em(28, 14);
    }
    th,
    td {
      padding: em(7, 14) em(14, 14);
    }
    th,
    td {
      &:first-child {
        width: em(224, 14);
      }
    }
    label {
      width: em(
        248,
        14
      ); // th & td:first-child - 4px (display: inline-blok white space)
    }
    // Trick to bypass border-collapse effect
    tfoot::before {
      height: em(7, 14);
    }
    .cta {
      margin: em(28, 14) 0;
    }
    p {
      line-height: lh(21, 14);
    }
    // Custom select
    .nice-select {
      padding-right: 40px;
      padding-left: 20px;
      height: em(36, 14);
      line-height: lh(36, 14);
      // List & options
      .list {
        line-height: inherit;
        transform: scale(0.75) translateY(-18);
      }
      .option {
        padding-left: 20px;
        padding-right: 40px;
        min-height: em(36, 14);
        line-height: inherit;
      }
      // Arrow
      &::after {
        border-bottom-width: 5px;
        border-right-width: 5px;
        height: 10px;
        width: 10px;
        margin-top: -10px;
      }
    }
  }

  .notice {
    font-size: 85%;
    opacity: 0.9;
    margin-top: 0;
  }

  .trainingContact {
    text-align: center;
    p {
      text-align: left;
    }
    .cta a {
      width: auto;
      padding: 1em;
      background-color: #147b90;
      display: inline-block;
      margin: 0 auto;
    }
  }
}

// As per https://blog.theodo.com/2018/01/responsive-iframes-css-trick/
.yt-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}
.yt-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.training .ctaContainer {
  text-align: center;
  margin-bottom: 0;
  .cta a {
    width: auto;
    padding: 1em;
  }
}

.training .darkCta .cta a {
  background-color: #147b90;
  display: inline-block;
  margin-top: 0;
}
