.l-grid {
  list-style: none;
  margin: 0;
  padding: 0;

  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;
}

.l-grid__2cols {
  grid-template-columns: repeat(2, 1fr);
}

.l-grid__3cols {
  grid-template-columns: repeat(3, 1fr);
}

.l-grid__4cols {
  grid-template-columns: repeat(4, 1fr);
}

.l-grid__2-1cols {
  grid-template-columns: 2fr 1fr;
}

@include mq($until: tablet) {
  .l-grid {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }
}

@include mq(tablet, desktop) {
  .l-grid__4cols {
    grid-template-columns: repeat(3, 1fr);
  }
}
