.c-card {
  color: $dark-grey;

  article {
    height: 100%;
  }

  // Meta is using absolute positionning. We must therefore ensure it has enough space.
  padding-bottom: 3rem; // Meta height + top and bottom padding
  position: relative;

  .c-card__meta {
    color: $dark-grey;
    font-size: 10pt;
    height: 1rem;
    width: calc(100% - 2rem);
    padding: 10px 1rem;
    background-color: $light-beige;
    border-top: 1px solid $beige;

    position: absolute;
    bottom: 0;

    display: flex;
    justify-content: space-between;
  }

  .c-card__reading-time::before {
    content: '⏱';
    margin-right: 5px;
  }

  time::after {
    content: '';
  }
}

.c-card__image {
  border-radius: 4px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
  border: 1px solid transparent;

  #{$link-states} {
    border: 1px solid $orange;
  }

  a {
    text-decoration: none;
  }

  .c-card__link {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .c-card__title,
  .c-card__content {
    color: $dark-grey;
    padding: 1rem;
  }

  .c-card__title,
  .c-card__content {
    margin-bottom: 1rem;
  }

  .c-card__content {
    font-size: 11pt;
    padding: 0 1rem;
  }

  .c-card__media {
    width: 100%;
    border-bottom: 1px solid #eee;
  }

  .c-card__title {
    font-size: 12pt;
    display: flex;
    justify-content: space-between;
  }

  .c-card__tags {
    margin: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;

    img {
      height: 40px;
      margin: 0 2px;
    }

    // Text tag
    span {
      padding: 3px 5px;
      text-decoration: none;
      border-radius: 5px;
      background-color: $beige;
      color: $white;
      font-size: 10pt;
    }
  }

  .c-card__tag {
    height: 20px;
  }
}
