// --------------- //
// --- ARTICLE --- //
// --------------- //

.reading-time::before {
  content: '⏱';
  margin-right: 5px;
}

// Screencasts
.video {
  // Free content
  > .free {
    position: relative;
  }
  // Free label
  h3 .free {
    color: $blue;
  }
  // Play button
  a[tabindex='-1'] {
    position: relative;
    display: block;
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
    }
    &::before {
      z-index: 1;
      border-style: solid;
      border-color: transparent;
      border-left-color: $white;
    }
    &::after {
      z-index: 0;
      background-color: $orange;
      box-shadow: 0 0 15px 5px rgba($dark-grey, 0.45);
    }
  }
}

// Support wrapper
.support-wrapper {
  overflow: hidden; // Formatting context
  background-color: $dark-white;
}

// --- Mobile & Tablet --- //

@include mq($until: desktop) {
  // Screencasts
  .video {
    // Play button
    a[tabindex='-1'] {
      &::before {
        margin-top: -18px;
        margin-left: -11px;
        border-width: 18px 0 18px 28px;
      }
      &::after {
        margin-top: -37px;
        margin-left: -37px;
        width: 74px;
        height: 74px;
        border-radius: 37px;
      }
    }
  }
}

// --- Mobile --- //

@include mq($until: tablet) {
  // -- Article page -- //

  // Latest wrapper
  .latest-wrapper {
    > section {
      border-top: em(6, 12) solid $dark-beige;
      &:first-child {
        overflow: hidden; // Formatting context
        background-color: $dark-white;
      }
    }
  }
}

// --- Tablet & Desktop --- //

@include mq($from: tablet) {
  // Main title
  .articles,
  .article,
  .archive {
    section > h1::before {
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
  // Screencasts (wide display)
  .wide {
    text-align: center;
    li {
      display: list-item;
    }
    img {
      width: 100%;
      height: auto;
    }
    .content-wrapper {
      padding-left: 0;
    }
  }

  // -- Article page -- //

  // Screencasts wrapper
  .screencast-wrapper {
    overflow: hidden; // Formatting context
  }
  // Latest wrapper
  .latest-wrapper {
    display: table;
    background-color: $dark-white;
    > section {
      box-sizing: border-box;
      display: table-cell;
      width: 50%;
    }
  }
}

// --- Tablet --- //

@include mq(tablet, desktop) {
  // Main title
  .articles,
  .article,
  .archive {
    section > h1::before {
      width: em(230, 30);
      background-position: em(-383, 30) em(-1074, 30);
    }
  }
  // Blogpost
  .blogpost-wrapper .presentation {
    li:last-child {
      margin-bottom: em(43, 14);
    }
  }
  // Screencasts (tiny view)
  .tiny .video {
    a[tabindex='-1'] {
      &::before {
        margin-top: -8px;
        margin-left: -4px;
        border-width: 8px 0 8px 12px;
      }
      &::after {
        margin-top: -20px;
        margin-left: -20px;
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
    }
  }

  // -- Article page -- //

  // Screencasts (wide display)
  .wide {
    li {
      margin-bottom: em(48, 14);
    }
    h3 {
      margin-top: em(12, 17);
    }
  }

  // Latest wrapper
  .latest-wrapper {
    border-top: em(7, 14) solid $dark-beige;
    padding-bottom: em(20, 14);
  }
}

// --- Desktop --- //

@include mq($from: desktop) {
  // Main title
  .articles,
  .article,
  .archive {
    section > h1::before {
      width: em(356, 40);
      background-position: 0 em(-1076, 40);
    }
  }
  // Screencasts
  .video {
    // Play button
    a[tabindex='-1'] {
      &::before {
        margin-top: -25px;
        margin-left: -12px;
        border-width: 25px 0 25px 36px;
      }
      &::after {
        margin-top: -50px;
        margin-left: -50px;
        width: 100px;
        height: 100px;
        border-radius: 50px;
      }
    }
  }
  // Screencasts (tiny view)
  .tiny .video {
    a[tabindex='-1'] {
      &::before {
        margin-top: -12px;
        margin-left: -6px;
        border-width: 12px 0 12px 18px;
      }
      &::after {
        margin-top: -24px;
        margin-left: -24px;
        width: 48px;
        height: 48px;
        border-radius: 24px;
      }
    }
  }
  // Blogpost
  .blogpost-wrapper .presentation {
    li:last-child {
      margin-bottom: em(63, 18);
    }
  }

  // -- Article page -- //

  // Screencasts (wide display)
  .wide {
    li {
      margin-bottom: em(56, 18);
    }
    h3 {
      margin-top: em(14, 22);
    }
  }
  // Latest wrapper
  .latest-wrapper {
    border-top: em(10, 18) solid $dark-beige;
    padding-bottom: em(30, 18);
  }
  // Call to action's background
  .article::after {
    // #footer's hgt & mt + #customer's hgt + #cta's hgt + 70px overflow
    height: em(870, 18);
  }
}

pre[class*='language-'][data-filename] {
  padding-bottom: 0;
  margin-bottom: 2em;
  position: relative;
  overflow: visible;

  &::after {
    position: absolute;
    left: 0;
    bottom: -1.5em;
    font-size: 80%;
    color: gray;
    content: '» ' attr(data-filename);
  }
}

pre[data-gist] {
  margin-bottom: 0;

  & + p.gist-footer {
    font-size: 80%;
    background: #eee;
    margin-bottom: 0.75rem;
    padding: 0.2rem 0.3rem;
    color: #666;
    & a {
      font-family: monospace;
      color: black;
    }
  }
}

.article blockquote,
.info blockquote {
  font-style: italic;
  border-left: 0.35em solid silver;
  margin: 0 0 1em 0;
  padding-left: 0.5em;

  cite {
    font-style: italic;
    font-weight: bold;
    color: silver;

    &::before {
      content: '— ';
    }
  }
}

#main {
  .screencastPimp {
    margin-top: em(-7, 14);
    margin-bottom: 3rem;
    border-top: em(7, 14) solid $blue;
    display: flex;
    padding: 1em 1em 1.5em;
  }
  .screencastLogo {
    flex-basis: 20%;
    margin: auto;
  }
  .screencastPitch {
    flex-basis: 80%;
    margin-left: 1em;
    h3 {
      padding-top: 0;
    }
  }
}

@include mq($until: tablet) {
  #main {
    .screencastPimp {
      flex-direction: column;
    }
    .screencastPitch {
      margin-left: 0;
      margin-top: 1em;

      h3 {
        text-align: center;
        margin-bottom: 1em;
      }
    }
  }
}

// asciinema embed player
.asciicast {
  display: flex !important; // overrides asciinema defaults
  justify-content: center;
}

code[class*='language-'],
pre[class*='language-'] {
  font-family: 'Operator Mono SSm A', 'Operator Mono SSm B', Consolas, Monaco,
    'Andale Mono', 'Ubuntu Mono', monospace;

  .token.keyword {
    font-weight: 500;
  }

  .token.comment {
    font-style: italic;
  }

  .token.operator {
    background: transparent;
  }
}
