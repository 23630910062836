.intro {
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    max-height: 300px;
    flex: 1 33%;
  }

  & > div {
    width: 100%;
    margin-bottom: 1rem;
  }
}

@include mq($until: tablet) {
  .intro {
    margin: 0 1rem;
    padding-bottom: em(56, 18);
    flex-wrap: wrap;
  }
}

@include mq($from: tablet) {
  .intro {
    padding: 0 em(180, 18) 0 em(180, 18);

    svg {
      margin-left: 2rem;
    }
  }
}
