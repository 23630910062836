// ------------- //
// --- ASIDE --- //
// ------------- //

// Customer, Sponsorship & Customized training
aside {
  background-color: $blue;
  &,
  a {
    color: $white;
  }
  figure {
    display: flex;
    margin: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    list-style: none;
  }
}

// Sponsorship & Customized training
#sponsorship,
#customized {
  overflow: hidden; // Formatting context
  h2 {
    border-bottom-color: rgba($white, 0.25);
  }
}

// Customer & Sponsorship
#customer,
#sponsorship {
  a {
    transition: opacity 0.5s ease;
    #{$link-states} {
      opacity: 0.75;
    }
  }
}

// Sponsorship
#sponsorship {
  a {
    color: $white;
  }
}

// Customized training
#customized {
  .cta {
    &::before,
    &::after {
      opacity: 0.25;
    }
  }
  p {
    color: $white;
  }
  a {
    color: $orange;
    background-color: $white;
  }
}

// Customer
#customer {
  background-color: $light-beige;
  h2 {
    color: $dark-grey;
  }
}

// Next talks
.next {
  h2 {
    text-align: left;
  }
  a {
    font-family: $extrabold;
  }
  a,
  time:last-child {
    &::after {
      content: '\0002F';
      display: inline-block;
      margin-left: 5px;
      font-family: $regular;
      font-weight: normal;
    }
  }
}

// --- Mobile --- //

@include mq($until: tablet) {
  // Customer
  #customer {
    display: none;
  }
  // Sponsorship
  #sponsorship {
    figure {
      border-bottom: em(3, 12) solid $white;
      padding-right: em(20, 12);
      padding-left: em(20, 12);
    }
    a {
      margin: em(10, 12) 0;
      width: 45%;
      &:nth-child(2n + 1) {
        padding-right: 5%;
      }
      &:nth-child(2n + 2) {
        padding-left: 5%;
      }
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  // Customized training
  #customized {
    border-bottom: em(3, 12) solid $white;
  }
  .customized-wrapper {
    padding: 0 em(20, 12);
  }
  // Next talks
  .next {
    margin: 0 em(20, 12) em(40, 12);
    padding: em(18, 14) em(20, 12) em(30, 14);
    h2 {
      margin: 0 0 em(20, 17);
    }
    ul {
      font-size: em(12, 12);
      padding-left: em(16, 12);
    }
  }
}

// --- Tablet & Desktop --- //

@include mq($from: tablet) {
  // Sponsorship & Customized training
  #sponsorship,
  #customized {
    @include sprite-background(false, false);
  }
  // Customized training
  .customized-wrapper {
    column-count: 3;
    // Rules useful to Chrome, Opera & Safari
    column-fill: auto;
  }
  // Next talks
  .next {
    @include sprite-background(false, false);
  }
}

// --- Tablet --- //

@include mq(tablet, desktop) {
  // Customer, Sponsorship & Customized training
  aside {
    padding-right: em(20, 14);
    padding-left: em(20, 14);
    h1,
    h2 {
      margin-right: 0;
      margin-left: 0;
    }
  }
  // Sponsorship & Customized training
  #sponsorship,
  #customized {
    &::before {
      top: em(14, 14);
      left: em(20, 14);
      width: em(128, 14);
      height: em(82, 14);
      background-position: em(-174, 14) 0;
    }
  }
  // Sponsorship
  #sponsorship {
    h2 {
      margin-top: em(33, 20);
      margin-left: em(120, 20);
      margin-bottom: em(36, 20);
      padding-right: em(120, 20);
    }
    figure {
      margin: 0 em(-20, 14) em(60, 14);
      min-height: em(144, 14);
    }
    a {
      margin: 0 em(20, 14);
    }
  }
  // Customized training
  #customized {
    padding-right: 0;
    padding-left: 0;
    &::before {
      top: em(7, 14);
      left: em(7, 14);
      width: em(188, 14);
      height: em(110, 14);
      background-position: em(-368, 14) em(-369, 14);
      z-index: -1;
    }
    h2 {
      margin-right: em(20, 20);
      margin-bottom: em(36, 20);
      margin-left: em(170, 20);
      padding: em(15, 20) em(180, 20) em(15, 20) em(30, 20);
      line-height: lh(30, 20);
    }
  }
  .customized-wrapper {
    padding-right: em(20, 14);
    padding-left: em(20, 14);
    column-gap: em(60, 18);
    // Rules useful to Chrome, Opera & Safari
    height: em(126, 14);
  }
  // Customer
  #customer {
    position: relative; // Useful to push block forward .cta background
    margin-top: em(-7, 14);
    border-top: em(7, 14) solid $blue;
    figure {
      padding-bottom: em(64, 14);
      min-height: em(56, 14);
    }
  }
  // Next talks
  .next {
    overflow: hidden; // Formatting context
    margin: em(-12, 14) em(20, 14) 0;
    padding: em(18, 14) em(20, 14) em(30, 14);
    &::before {
      width: em(50, 14);
      height: em(50, 14);
      top: em(7, 14);
      right: em(14, 14);
      background-position: em(-630, 14) em(-394, 14);
    }
    h2 {
      margin: 0 em(60, 24) em(18, 24) 0;
      padding-bottom: em(6, 24);
      font-size: rem(24);
      line-height: lh(30, 24);
    }
    ul {
      font-size: em(14, 14);
      padding-left: em(14, 14);
    }
  }
}

// --- Tablet internal down --- //

@include mq(tablet, tablet_upper_boundary) {
  // Next talks
  .next::before {
    top: em(24, 14);
  }
}

// --- Tablet internal up --- //

@include mq(tablet_middle_boundary, desktop) {
  //  Customized training
  .customized-wrapper {
    height: em(98, 14);
  }
}

// --- Desktop --- //

@include mq($from: desktop) {
  // Customer, Sponsorship & Customized training
  aside {
    figure {
      padding-right: em(60, 18);
      padding-left: em(60, 18);
    }
  }
  // Sponsorship & Customized training
  #sponsorship,
  #customized {
    h2 {
      margin-top: em(33, 26);
      margin-bottom: em(42, 26);
      margin-left: em(195, 26);
      padding-right: em(165, 26);
    }
  }
  // Sponsorship
  #sponsorship {
    &::before {
      top: em(18, 18);
      left: em(30, 18);
      width: em(170, 18);
      height: em(109, 18);
      background-position: 0 em(-88, 18);
    }
    figure {
      margin-bottom: em(70, 18);
      min-height: em(176, 18);
    }
  }
  // Customized training
  #customized {
    &::before {
      top: em(-12, 18);
      left: 0;
      width: em(252, 18);
      height: em(147, 18);
      background-position: em(-368, 18) em(-212, 18);
      z-index: -1;
    }
  }
  .customized-wrapper {
    padding: 0 em(30, 18);
    column-gap: em(40, 14);
    // Rules useful to Chrome, Opera & Safari
    height: em(117, 18);
  }
  // Customer
  #customer {
    margin-top: em(-10, 18);
    border-top: em(10, 18) solid $blue;
    figure {
      padding-bottom: em(84, 18);
      min-height: em(84, 18);
    }
  }
  // Next talks
  .next {
    margin: em(-14, 18) em(30, 18) 0;
    padding: em(22, 18) em(30, 18) em(34, 18);
    &::before {
      width: em(56, 18);
      height: em(60, 18);
      top: em(9, 18);
      right: em(18, 18);
      background-position: em(-630, 18) em(-324, 18);
    }
    h2 {
      font-size: rem(22);
      margin: 0 em(54, 18) em(19, 22) 0;
      line-height: lh(42, 22);
    }
    ul {
      font-size: rem(16);
      padding-left: em(16, 16);
    }
  }
}

aside.tip {
  padding: 24px 32px;
  border-left: 3px solid $blue;
  max-width: 600px;
  border-radius: 5px;
  margin: 20px auto;
  position: relative;
  color: $dark-grey;
  background-color: $light-beige;
  box-shadow: 0.3px 0.3px 0px rgba(0, 0, 0, 0.035),
    2px 2px 0px rgba(0, 0, 0, 0.07);

  &:before {
    content: '💡';
    display: block;
    position: absolute;
    background-color: $light-beige;
    border: 3px solid $blue;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 40px;
    left: -25px;
    top: 15px;
    text-align: center;
  }

  strong {
    margin-bottom: 10px;
  }

  a {
    color: $blue;

    &:hover,
    &:focus {
      color: $dark-blue;
    }
  }
}
