form {
  padding: 2rem;
  margin: 2rem 0;
  background-color: $light-beige;

  label {
    display: block;

    &:has(~ *[required]):after {
      content: '*';
      font-size: 80%;
      color: red;
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='tel'],
  textarea {
    width: 100%;
    padding: 0.1rem 0.2rem;
  }

  label[hidden] {
    display: none;
  }
}

@include mq($from: desktop) {
  form {
    .cta {
      // Decorative borders
      &::before,
      &::after {
        width: calc((100% - 300px - 60px) / 2);
      }
      a,
      button {
        width: em(300, 18);
      }
    }
  }
}
