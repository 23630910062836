.services {
  &.englishHome main {
    h1 {
      font-size: inherit;
      line-height: 1;
      font-size: 16px;
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-color: transparent;
      span {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.2);
        padding: 0.5em 0;
      }
    }

    #logo {
      display: none;
    }
    #header {
      padding-left: 0;
    }

    article:nth-of-type(3n + 1) .cta a {
      background-color: #1eb9d7; // bleu: 1, 4
    }
    article:nth-of-type(3n + 2) .cta a {
      background-color: #f6b361; // orange: 2, 5
    }
    article:nth-of-type(3n) .cta a {
      background-color: #f15a25; // rouge: 3
    }
  }

  article {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 2rem;
  }

  // Call to action
  .cta {
    margin: em(56, 18) 0 em(98, 18);

    a {
      background-color: $light-orange;
    }
  }

  .services__stack a,
  .services__quality a {
    background-color: $blue;
  }

  .services__poc a {
    background-color: $dark-orange;
  }

  .services__audit a {
    background-color: $light-orange;
  }
}

@include mq($until: tablet) {
  .services.englishHome main h1 {
    position: relative;
    font-size: 16px;
    height: 8em;
    background-size: cover;
    background-image: url('../images/illustration-small.jpg');
  }

  .services {
    .inner-wrapper {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    article {
      &:first-of-type {
        margin-bottom: 3em;
      }

      & > div,
      svg,
      img {
        width: 100%;
      }

      svg,
      img {
        order: -1;
        max-height: 100px;
      }
    }
  }
}

@include mq($from: tablet) {
  .services.englishHome main {
    h1 {
      background-image: url('../images/illustration.jpg');
    }
  }

  .services {
    .inner-wrapper {
      padding-top: 3rem;
    }
    article {
      & > div,
      svg,
      img {
        width: 45%;
      }

      &:nth-of-type(2n) {
        svg,
        img {
          order: -1;
        }
      }
    }
  }
}

@include mq(tablet, desktop) {
  .services.englishHome main {
    h1 {
      height: em(700, 14);
      background-size: cover;
    }
    #header {
      margin-left: 0;
    }
  }
}

@include mq($from: desktop) {
  .services.englishHome main h1 {
    margin-top: 0;
    padding-bottom: 0;
    height: em(700, 18);
    background-size: 1400px 700px;
    // Decorative border
    &::after {
      content: normal;
    }

    span {
      height: 5rem;
    }
  }
}
