// Call to action
.cta {
  position: relative;
  text-align: center;
  font-family: $bold;

  // Decorative borders
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -3px;
    height: 6px;
    background-color: $dark-beige;
  }
  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
  // Button
  a,
  button {
    border: 0;
    display: inline-block;
    text-decoration: none;
    color: $white;
    background-color: $orange;
    border-radius: 10px;
    padding: 1rem 2rem;
    cursor: pointer;

    #{$link-states} {
      text-decoration: underline;
    }
    span {
      text-transform: uppercase;
    }
  }
  span {
    display: block;
  }
}

@include mq($until: tablet) {
  .cta {
    margin: em(70, 12) 0 em(40, 12);
    // Decorative borders
    &::before,
    &::after {
      width: calc(
        (100% - 228px - 40px) / 2
      ); // (100% - .cta width - .cta borders) / 2
    }
    a,
    button {
      padding: em(14, 12) 0;
      width: em(228, 12);
      line-height: lh(16, 12);
    }
    span {
      font-size: em(17, 12);
    }
  }
}

@include mq(tablet, desktop) {
  // Call to action
  .cta {
    // Decorative borders
    &::before,
    &::after {
      width: calc(
        (100% - 266px - 40px) / 2
      ); // (100% - .cta width - .cta borders) / 2
    }
    a {
      padding: em(17, 14) 0;
      width: em(266, 14);
      line-height: lh(19, 14);
    }
    span {
      font-size: em(20, 14);
    }
  }
}

@include mq($from: desktop) {
  .cta {
    margin: 2rem 0 3rem;
    // Decorative borders
    &::before,
    &::after {
      width: calc(
        (100% - 342px - 60px) / 2
      ); // (100% - .cta width - .cta borders) / 2
    }
    a {
      padding: em(20, 18) 0;
      width: em(342, 18);
      line-height: lh(22, 18);
    }
    span {
      font-size: em(26, 18);
    }
  }
}
