// ------------------ //
// --- TOPIC LIST --- //
// ------------------ //

.topic {
  margin-top: 0;
  a {
    @include sprite-background(false, false);
    display: block;
    color: $dark-grey;
    text-decoration: none;
    #{$link-states} {
      h3 {
        text-decoration: underline;
      }
    }
  }
  h3 {
    font-family: $extrabold;
    text-transform: uppercase;
  }
  strong {
    @include sprite-background(false, false, after);
    display: block;
    font-family: $regular;
    background-color: $dark-beige;
    // Calendar image
    &::after {
      top: 50%;
    }

    .remoteSessionLabel {
      display: inline-block;
      margin-left: 0.6em;
      padding: 0.1em 0.4em;
      font-size: 85%;
      color: black;
      background-color: $light-grey;
      font-family: $bold;
    }
  }
}

// --- Mobile --- //

// @include mq($until: tablet) {
.topic {
  li:nth-child(even) {
    border-top: 1px solid $dark-beige;
    border-bottom: 1px solid $dark-beige;
  }
  a {
    padding: em(35, 12) em(20, 12) em(35, 12) em(120, 12);
    min-height: em(78, 12); // Height of background image
    // Background images
    &::before {
      left: 20px;
      top: 50%;
      margin-top: em(-39, 12);
      width: em(81, 12);
      height: em(78, 12);
    }
  }
  strong {
    margin: em(10, 12) 0;
    padding: em(10, 12) em(66, 12) em(10, 12) em(20, 12);
    // Calendar image
    &::after {
      right: 15px;
      margin-top: em(-13, 12);
      width: em(30, 12);
      height: em(27, 12);
      background-position: -570px -450px;
    }
  }
  // Background images
  .git-total,
  .git-total-3j {
    a::before {
      background-position: -810px -324px;
    }
  }
  .react-avance,
  .react-pwa {
    a::before {
      background-position: -902px -324px;
    }
  }
  .node-js a::before {
    background-position: -992px -324px;
  }
  .es-total,
  .es-total-3j {
    a::before {
      background-position: -1092px -324px;
    }
  }
  .architecture-css a::before {
    background-position: -1296px -324px;
  }
  .webpack a::before {
    background-position: -1194px -324px;
  }
  .swiftui a::before,
  .apple-combine a::before,
  .swiftui-fr a::before,
  .tests-unitaires-ios a::before {
    background: center center no-repeat url(../images/trainings/swiftui.png);
    background-size: contain;
  }
  .performances-web,
  .performances-web-3j {
    a::before {
      background: center center no-repeat
        url(../images/trainings/web-performance.png);
      background-size: contain;
    }
  }

  .typescript,
  .typescript-avance {
    a::before {
      background: center center no-repeat
        url(../images/trainings/typescript.png);
      background-size: contain;
    }
  }
}
// }

// --- Tablet & Desktop --- //
/*
@include mq($from: tablet) {
  .topic {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    text-align: center;
    li {
      box-sizing: border-box;
      flex: 1 1 33.33333%;
      max-width: 33.33333%;
      // Background images
      border-right: 1px solid $dark-beige;
      border-left: 1px solid $dark-beige;
      &::before {
        left: 50%;
      }
      &:first-child,
      &:nth-child(1n + 3) {
        border-top: 1px solid $dark-beige;
      }
      // &:first-child, &:last-child {
      //   border-right: 1px solid $dark-beige;
      //   border-left: 1px solid $dark-beige;
      // }
    }
  }
}

// --- Tablet --- //

@include mq(tablet, desktop) {
  .topic {
    a {
      padding: em(168, 14) em(20, 14) em(28, 14);
      // Background images
      &::before {
        top: 24px;
        margin-left: em(-66, 14); // Half width
        //left: em(63, 14);
        //margin-top: em(-60, 14);
        width: em(132, 14);
        height: em(122, 14);
      }
    }
    // Background images
    .git-total,
    .git-total-3j {
      a::before {
        background-position: em(-630, 14) em(-196, 14);
      }
    }
    .web-apps-modernes a::before {
      background-position: em(-763, 14) em(-196, 14);
    }
    .node-js a::before {
      background-position: em(-896, 14) em(-196, 14);
    }
    .es-total,
    .es-total-3j {
      a::before {
        background-position: em(-1036, 14) em(-196, 14);
      }
    }
    .architecture-css a::before {
      background-position: em(-1316, 14) em(-196, 14);
    }
    .webpack a::before {
      background-position: em(-1176, 14) em(-196, 14);
    }
    .swiftui a::before,
    .apple-combine a::before,
    .swiftui-fr a::before,
    .tests-unitaires-ios a::before {
      background: center center no-repeat url(../images/trainings/swiftui.png);
      background-size: contain;
    }
    .performances-web,
    .performances-web-3j {
      a::before {
        background: center center no-repeat
          url(../images/trainings/web-performance.png);
        background-size: contain;
      }
    }
    h3 {
      margin-bottom: em(10, 20);
      font-size: rem(20);
      line-height: lh(36, 20);
    }
    p {
      line-height: lh(20, 14);
    }
    strong {
      margin: em(12, 14) 0;
      padding: em(12, 14) em(70, 14) em(12, 14) em(20, 14);
      // Calendar image
      &::after {
        right: 18px;
        margin-top: em(-15, 14);
        width: em(33, 14);
        height: em(32, 14);
        background-position: em(-567, 14) em(-413, 14);
      }
    }
  }
}

// --- Desktop --- //

@include mq($from: desktop) {
  .topic {
    a {
      padding: em(252, 18) em(30, 18) em(42, 18);
      &::before {
        top: 28px;
        margin-left: em(-103, 18); // Half width
        width: em(206, 18);
        height: em(194, 18);
      }
    }
    // Background images
    .git-total,
    .git-total-3j {
      a::before {
        background-position: em(-423, 18) 0;
      }
    }
    .web-apps-modernes a::before {
      background-position: em(-639, 18) 0;
    }
    .node-js a::before {
      background-position: em(-864, 18) 0;
    }
    .es-total,
    .es-total-3j {
      a::before {
        background-position: em(-1089, 18) 0;
      }
    }
    .architecture-css a::before {
      background-position: em(-1535, 18) 0;
    }
    .webpack a::before {
      background-position: em(-1314, 18) 0;
    }
    .swiftui a::before,
    .apple-combine a::before,
    .swiftui-fr a::before,
    .tests-unitaires-ios a::before {
      background: center center no-repeat url(../images/trainings/swiftui.png);
      background-size: contain;
    }
    .performances-web,
    .performances-web-3j {
      a::before {
        background: center center no-repeat
          url(../images/trainings/web-performance.png);
        background-size: contain;
      }
    }
    h3 {
      margin-bottom: em(13, 26);
      font-size: rem(26);
      line-height: lh(39, 26);
    }
    p {
      font-size: em(16, 18);
      line-height: lh(24, 16);
    }
    strong {
      margin: em(14, 18) 0;
      padding: em(14, 18) em(30, 18);
      // Calendar image
      // &::after {
      //   right: 26px;
      //   margin-top: -18px;
      //   width: em(35, 18);
      //   height: em(36, 18);
      //   background-position: em(-567, 18) em(-369, 18);
      // }
    }
  }
}
*/
