// -------------- //
// --- HEADER --- //
// -------------- //

// Hamburger icon animation
@import '../utilities/hamburger';

#header {
  font-size: rem(16);
  line-height: 1;
}

.header-toplevel {
  display: flex;
  background-color: $orange;
  // The left-side logo needs homoeneous surrounding space, hence 0.5 x 3
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  gap: 1.5rem;
  justify-content: stretch;
  align-items: center;

  .filler {
    flex-grow: 1;
  }
}

// Main nav
#nav {
  font-family: $bold;
  text-transform: uppercase;
  color: $dark-grey;

  ul {
    padding-left: 0;
    list-style: none;
  }

  li {
    display: inline;
    margin-right: 1.5rem;
  }

  a {
    color: $white;
    text-decoration: none;
    &:focus,
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }
}

#logo {
  $logo-width: 35px;

  svg {
    display: block;
    height: $logo-width;
    width: $logo-width;

    path {
      fill: $white !important;
    }
  }
}

// Language toggler
#toggle-lang {
  font-family: $bold;
  text-decoration: none;
  text-transform: uppercase;
  color: $white;
  #{$link-states} {
    text-decoration: underline;
  }
}

#rss-feed {
  $rss-logo-width: 1.5rem;

  svg {
    display: block;
    height: $rss-logo-width;
    width: $rss-logo-width;
  }
}

#toggle-nav {
  display: none;
}

// Breadcrumb
#breadcrumb {
  background-color: $dark-beige;
  padding: 0 em(20, 14);
  line-height: lh(48, 14);
  a,
  span {
    padding-right: em(14, 14);
    &::after {
      top: em(7, 14);
      right: em(2, 14);
      width: em(7, 14);
      height: em(7, 14);
    }
  }
  a,
  span {
    @include sprite-background(false, false, after);
    color: $dark-grey;
    // Background image
    &::after {
      background-position: 0 0;
    }
    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }
}

// Expanded mobile nav
.nav-is-displayed {
  .header-toplevel {
    padding-bottom: 0;
    border-bottom: 0.5rem solid $dark-beige;
  }
  #nav {
    display: block;
    background-color: $dark-beige;
    border-bottom: 2px solid $orange;

    ul {
      display: flex;
      flex-direction: column;
      padding: 1rem;
      gap: 1rem;
    }

    a {
      display: block;
      color: $dark-grey;
    }
  }
}

// --- Mobile & Tablet --- //

@include mq($until: tablet) {
  .header-toplevel {
    // The hamburger menu introduces a lot of extra content height, causing a
    // perceivable height jump; we're compensating with lower block paddings.
    padding-block: 0.25rem;
  }
  #nav {
    display: none;
  }
  // Mobile nav button
  #toggle-nav {
    display: block;
    overflow: hidden;
    position: relative;
    line-height: 1;
    height: em(40, 14);
    // This introduces a slight gap discrepancy, but ensures a neat square
    // rendering when active.
    width: em(40, 14);
    .content {
      position: absolute;
      top: -100px;
      left: -200px;
    }
    .hamburger-box {
      height: inherit;
    }
  }
  // Breadcrumb
  #breadcrumb {
    display: none;
  }
}
