.info {
  h1.with-date {
    line-height: 2;
  }

  .heroUnit {
    text-align: center;
    margin-block: 2rem;

    img {
      max-height: 250px;
    }
  }

  .cta.audit a,
  .cta.dev a {
    background-color: $light-orange;
  }
  .cta.stack a {
    background-color: $blue;
  }
  .cta.poc a {
    background-color: $dark-orange;
  }

  .locales {
    padding: 0.5rem 1rem;
    background-color: $light-beige;
  }

  img {
    max-width: 100%;
  }

  .update {
    padding: 0.5rem 1rem;
    background-color: $light-beige;
    font-style: italic;
    border: 5px solid $blue;
  }

  p.highlight {
    font-style: italic;
    font-size: 1.2em;
    color: #1eb9d7;
    background: transparent url(../images/icon/attention.svg) left 0.1em
      no-repeat;
    padding-left: 35px;
  }
}

// --- Tablet --- //

@include mq(tablet, desktop) {
  .info h2 {
    margin-right: 0;
  }
}

// --- Desktop --- //

@include mq($from: desktop) {
  .info {
    &.full-width-images .inner-wrapper section.no-internal-nav img {
      max-width: 100%;
    }

    .inner-wrapper {
      // Sections
      section {
        padding: 0 em(30, 18) em(56, 18) em(430, 18);

        &.no-internal-nav {
          padding: em(30, 18) em(180, 18) em(56, 18) em(180, 18);

          img {
            max-width: 70%;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
    h2 {
      margin: 0 0 em(14, 26);
      padding-top: em(32, 26);
      text-align: left;
    }
    h3 {
      margin: 0 0 em(14, 26) 0;
      padding-top: em(32, 26);
      text-align: left;
    }
    ul {
      padding-left: em(18, 18);
    }
    // Code
    code,
    tt {
      line-height: lh(30, 18);
    }
  }
}
