body.workshops {
  .workshops-list {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 0 2rem 4rem;

    @include mq($until: tablet) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      margin: 0 0 4rem;
    }
  }

  article {
    min-width: 350px;
    max-width: 400px;
    flex-grow: 1;

    text-align: center;
    border-radius: 10px;
    box-shadow: 1.9px 1.7px 2.2px rgba(0, 0, 0, 0.011),
      4.5px 4.2px 5.3px rgba(0, 0, 0, 0.02),
      8.5px 7.9px 10px rgba(0, 0, 0, 0.028),
      15.2px 14.1px 17.9px rgba(0, 0, 0, 0.036),
      28.4px 26.3px 33.4px rgba(0, 0, 0, 0.046),
      68px 63px 80px rgba(0, 0, 0, 0.07);

    @include mq($until: tablet) {
      max-width: 100%;
      padding: 2rem 0;
      box-shadow: none;
    }

    a {
      display: block;
      padding: 3rem 2rem;
    }
  }

  article:hover,
  article:focus {
    box-shadow: 1.9px 1.7px 2.2px rgba(0, 0, 0, 0.024),
      4.5px 4.2px 5.3px rgba(0, 0, 0, 0.043),
      8.5px 7.9px 10px rgba(0, 0, 0, 0.061),
      15.2px 14.1px 17.9px rgba(0, 0, 0, 0.078),
      28.4px 26.3px 33.4px rgba(0, 0, 0, 0.099),
      68px 63px 80px rgba(0, 0, 0, 0.15);
    filter: brightness(110%);
  }

  .workshops-list a {
    text-decoration: none;
    color: #111;
  }

  h2 {
    color: #fff;
    text-transform: none;
    text-shadow: 1px 1px 2px #212121;
    border: none;
    font-family: $extrabold;
    font-size: 1.9rem;
    margin: 0 0 10px;
  }

  .workshop-date {
    display: block;
    font-family: $bold;
  }

  .workshops__git-masterclass {
    background-color: $git-red;
    a {
      color: white;
    }
  }

  .workshops__js-masterclass {
    background-color: $js-yellow;
  }

  .workshops__react-masterclass {
    background-color: $react-blue;
  }

  .workshop-trainer-avatar {
    display: block;
    width: 10rem;
    margin: 2rem auto;
  }
}
