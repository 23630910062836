// Imports
@import 'utilities/variables';
@import 'utilities/normalize';

// Gerenic
@import 'typo';
@import 'utilities/select';
@import 'utilities/popup.scss';

// Layout
@import 'layouts/header';
@import 'layouts/content';
@import 'layouts/aside';
@import 'layouts/footer';
@import 'layouts/grids';

// Pages
@import 'pages/about';
@import 'pages/news';
@import 'pages/article';
@import 'pages/articles';
@import 'pages/trainings';
@import 'pages/contact';
@import 'pages/customer';
@import 'pages/home';
@import 'pages/training';
@import 'pages/info';
@import 'pages/services';
@import 'pages/screencast';
@import 'pages/workshop';
@import 'pages/workshops';

@import 'components/buttons';
@import 'components/cards';
@import 'components/forms';
@import 'components/intro';
@import 'components/pagination';
@import 'components/topics';

// Dedicated to training page print
@import 'print';

// Desktop
@include mq($from: desktop) {
  body {
    position: relative;
    background-color: $light-beige;
  }
  #header,
  .inner-nav,
  #main,
  .inner-wrapper,
  #customer,
  #customized,
  .article .cta,
  .news .cta,
  .news #sponsorship,
  #testimony,
  #footer {
    position: relative;
    z-index: 2; // Push elements forward body::before
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
  }
  #nav .inner-nav,
  .inner-wrapper,
  #customized,
  .article .cta,
  .news .cta,
  .news #sponsorship {
    width: em(1200, 18);
  }
}

// --- Wide --- //

@include mq($from: wide) {
  #main {
    width: em(1400, 18);
  }
  #header {
    width: em(1200, 16);
  }
}
