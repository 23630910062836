body.screencast {
  .inner-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    margin: 2rem 0;
  }

  .video-container iframe,
  .video-container img,
  .video-container object,
  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  a.video-container {
    display: block;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 2;
      background-color: $dark-grey;
      opacity: 0.6;
    }

    &::after {
      content: '▶';
      font-size: 40px;
      position: absolute;
      cursor: pointer;
      z-index: 3;
      text-align: center;
      width: 70px;
      padding: 5px 0 5px 10px;
      border-radius: 50%;
      color: $white;
      background-color: $dark-orange;
      left: calc(50% - 40px);
      top: calc(50% - 25px);
      border: 1px solid $white;
      transition: background-color 0.5s ease;
    }

    &:hover::after {
      background-color: $orange;
    }
  }

  #chapters {
    border-left: 1px solid $dark-beige;
    font-size: 11pt;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;

      li {
        padding-bottom: 0.25rem;
      }

      .entry {
        display: flex;
        padding: 0.25rem;
        gap: 0.5rem;
        align-items: flex-start;

        .timecode {
          color: $blue;
          min-width: 5ex;
          text-align: right;
        }
      }

      a {
        text-decoration: none;
        &:hover,
        &:active,
        &:focus {
          color: $orange;
          background: #f4f4f4;
          border-radius: 0.3rem;
          font-weight: bold;
        }
      }
    }

    &.extended .entry .timecode {
      min-width: 6ex;
    }
  }

  #content,
  #chapters {
    padding: 0 2rem 2rem;
  }
}

@include mq($from: tablet) {
  body.screencast {
    .inner-wrapper {
      flex-wrap: nowrap;
    }

    #content {
      padding: 2rem 3rem;
    }

    #content,
    #chapters {
      padding-bottom: 4rem;
    }

    #chapters {
      min-width: 300px;
    }

    a.video-container::after {
      font-size: 60px;
      width: 100px;
      padding: 5px 0 5px 10px;
      left: calc(50% - 50px);
      top: calc(50% - 35px);
    }
  }
}

@include mq($from: desktop) {
  .screencast #content.no-timeline {
    padding: em(30, 18) em(180, 18) em(56, 18) em(180, 18);
  }
}
