// ---------------- //
// --- CUSTOMER --- //
// ---------------- //

// Category
.category {
  &:nth-child(even) {
    overflow: hidden; // Formatting context
    background-color: $dark-white;
  }
}

// List
.customers-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  font-family: $bold;
  text-align: center;
  li {
    box-sizing: border-box;
  }
  // Testimony
  .testimony {
    text-align: left;
    background-color: $dark-beige;
    img {
      border-radius: 45px;
    }
  }
  // Links
  li > a {
    display: table;
    height: 100%;
    width: 100%;
    max-height: 140px;
    color: $dark-grey;
    text-decoration: none;
    #{$link-states} {
      color: $orange;
      img {
        filter: none;
      }
    }
  }
  a img {
    vertical-align: bottom;
    filter: grayscale(1);
    max-width: 90%;
    max-height: 72px;
  }
  // Fake table-row behavior
  .row-wrapper {
    display: table-row;
    height: 100%;
    background-color: $light-beige;
  }
  // Image wrapper
  .image-wrapper {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
  }
  // Text wrapper
  .text-wrapper {
    display: block;
    width: 100%;
  }

  &:last-child {
    margin-bottom: 3em;
  }
}

// --- Mobile --- //

@include mq($until: tablet) {
  .customer {
    // Introduction
    .introduction {
      margin-top: em(20, 12);
    }
    // Last category
    .category:last-child {
      padding-bottom: em(40, 12);
    }
  }
  // Customers list
  .customers-list {
    padding: 0 em(10, 12);
    li {
      margin: 0 em(10, 12) em(20, 12);
      width: calc(100% / 2 - 20px); // element width - li's paddings
      a img {
        margin: em(15, 12) 0;
      }
    }
    // Fake table-row behavior
    .row-wrapper {
      padding: 0 em(10, 12);
    }
    // Text wrapper
    .text-wrapper {
      padding: em(2, 14) 0;
      line-height: lh(15, 12);
    }
    // Testimony
    .testimony {
      padding: em(20, 12);
      width: calc(100% - 20px); // element width - li's paddings
    }
  }
}

// --- Tablet & Desktop --- //

@include mq($from: tablet) {
  .customer {
    // Main title
    section > h1::before {
      bottom: 0;
      right: 20px;
    }
    h2 {
      text-align: left;
    }
  }
  .customers-list {
    // Testimony
    .testimony {
      order: 2;
    }
  }
}

// --- Tablet --- //

@include mq(tablet, desktop) {
  .customer {
    // Main title
    section > h1::before {
      width: em(158, 30);
      height: em(99, 30);
      background-position: em(-783, 30) em(-557, 30);
    }
    // Introduction
    .introduction {
      margin-top: em(24, 14);
    }
    // Last category
    .category:last-child {
      padding-bottom: em(72, 14);
    }
  }
  // Customers list
  .customers-list {
    padding: 0 em(10, 14);
    li {
      margin: 0 em(10, 14) em(24, 14);
      width: calc(100% / 4 - 20px); // element width - li's paddings
      a img {
        margin: em(18, 14) 0;
      }
    }
    // With testimony
    &.has-testimony {
      li:nth-child(2),
      li:nth-child(3) {
        order: 1;
      }
      li:nth-child(n + 4) {
        order: 3;
      }
    }
    // Testimony
    .testimony {
      margin-top: em(
        -72,
        16
      ); // p's mt/2 + h2's mb + h2's bb + h2's pb + h2's fs
      padding: em(24, 14) em(20, 14);
      width: calc(50% - 20px); // element width - li's paddings
    }
    // Fake table-row behavior
    .row-wrapper {
      padding: 0 em(10, 14);
    }
    // Text wrapper
    .text-wrapper {
      padding: em(3, 14) 0;
      line-height: lh(18, 14);
    }
  }
}

// --- Desktop --- //

@include mq($from: desktop) {
  .customer {
    // Background color
    &::after {
      height: em(
        346,
        18
      ); // #footer's hgt & mt + #customer's hgt & mt + 70px overflow
    }
    // Main title
    section > h1::before {
      width: em(280, 40);
      height: em(168, 40);
      background-position: em(-333, 40) em(-657, 40);
    }
    // Introduction
    .introduction {
      margin-top: em(64, 18);
    }
    // Last category
    .category:last-child {
      padding-bottom: em(84, 18);
    }
  }
  // Customers list
  .customers-list {
    font-size: rem(16);
    padding: 0 em(15, 16);
    li {
      margin: 0 em(15, 16) em(28, 16);
      width: calc(100% / 6 - 30px); // element width - li's paddings
      a img {
        margin: em(21, 16) 0;
      }
    }
    // With testimony
    &.has-testimony {
      li:nth-child(2),
      li:nth-child(3),
      li:nth-child(4) {
        order: 1;
      }
      li:nth-child(n + 5) {
        order: 3;
      }
    }
    // Testimony
    .testimony {
      margin-top: em(-72, 14); // p mt/2 + h2 mb + h2 bb + h2 pb + h2 fs
      padding: em(28, 16) em(30, 16);
      width: calc(50% - 30px); // element width - li's paddings
    }
    blockquote {
      font-size: em(14, 16);
      line-height: lh(24, 14);
    }
    // Fake table-row behavior
    .row-wrapper {
      padding: 0 em(15, 16);
    }
    // Text wrapper
    .text-wrapper {
      padding: em(4, 16) 0;
      line-height: lh(20, 16);
    }
  }
}
