// ------------ //
// --- NEWS --- //
// ------------ //

// Main content's header
.inner-wrapper > header {
  border-bottom-style: solid;
  border-bottom-color: $dark-beige;
}

// Talks
.presentation {
  .organizer,
  .sponsor {
    position: relative;
    background-color: $light-beige;
    // Right corner
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      z-index: 0;
      top: -1px;
      right: -1px;
      height: 100%;
    }
    &::before {
      height: 1px;
      background-image: linear-gradient(to left, $grey, transparent);
    }
    &::after {
      width: 1px;
      height: 100%;
      background-image: linear-gradient(to bottom, $grey, transparent);
    }
  }
}

// Ribbon
.news .ribbon {
  left: auto;
  transform: none;
}

// Call to action
.article .cta,
.news .cta {
  @include sprite-background(span, true);
  margin-top: 0;
  margin-bottom: 0;
  background-color: transparent;
  &::after {
    content: normal;
  }
  span {
    font-size: inherit;
  }
  // Button
  a {
    text-transform: uppercase;
  }
}

// --- Mobile & Tablet --- //

@include mq($until: desktop) {
  // Call to action's background
  .article .cta,
  .news .cta {
    background-color: $dark-beige;
  }
  // Ribbon
  .news .ribbon {
    right: -4px;
  }
}

// --- Mobile --- //

@include mq($until: tablet) {
  // Main content's header
  .inner-wrapper > header {
    margin-top: em(20, 12);
    border-bottom-width: em(6, 12);
    padding: 0 em(20, 12) em(24, 12);
  }
  // Talks
  .presentation {
    .organizer,
    .sponsor {
      margin-top: em(-10, 12);
      margin-bottom: em(-10, 12);
      padding: em(20, 12);
      // Right corner
      &::before {
        width: em(204, 12);
      }
      &::after {
        height: em(204, 12);
      }
      + .organizer,
      + .sponsor {
        margin-top: em(40, 12);
      }
    }
  }
  // Ribbon
  .news .ribbon {
    font-size: rem(13);
    line-height: lh(26, 13);
  }
  // Call to action
  .article .cta,
  .news .cta {
    padding: em(70, 12) 0 em(40, 12);
    // Strip
    &::before {
      top: 3px;
      left: em(20, 12);
      width: em(48, 12);
      height: em(120, 12);
      background-position: em(-258, 12) em(-84, 12);
    }
    span {
      margin: 0 em(20, 12) em(20, 12);
      line-height: lh(15, 12);
    }
    // Button
    a {
      padding: em(7, 12) 0;
      width: em(204, 12);
    }
  }
}

// --- Tablet & Desktop --- //

@include mq($from: tablet) {
  // Main title
  .news section > h1::before {
    top: 0;
    bottom: 0;
  }
  .inner-wrapper {
    // Main content's header
    > header {
      display: table;
      box-sizing: border-box;
      width: 100%;
      div,
      aside {
        display: table-cell;
      }
    }
  }
}

// --- Tablet --- //

@include mq(tablet, desktop) {
  // Main title
  .news section > h1::before {
    right: -60px;
    width: em(210, 30);
    background-position: em(-619, 30) em(-912, 30);
  }
  // Main content's header
  .inner-wrapper > header {
    margin-top: em(24, 14);
    border-bottom-width: em(7, 14);
    padding: 0 rem(20) em(29, 14);
    div {
      padding-right: em(20, 14);
    }
  }
  // Talks
  .presentation {
    .organizer,
    .sponsor {
      padding: em(14, 14);
      // Right corner
      &::before {
        width: em(252, 14);
      }
    }
  }
  .news .presentation {
    margin-right: em(9, 14);
    margin-left: em(9, 14);
    li {
      margin-top: 0;
      margin-bottom: 0;
      padding: em(12, 14) em(11, 14);
      &:first-child {
        margin-top: em(12, 14);
      }
      &:last-child {
        margin-bottom: em(24, 14);
      }
    }
  }
  // Ribbon
  .news .ribbon {
    font-size: rem(13);
    line-height: lh(26, 13);
  }
  // Call to action
  .article .cta,
  .news .cta {
    padding: em(84, 14) 0 em(48, 14);
    // Strip
    &::before {
      top: 2px;
      left: em(87, 14);
      width: em(56, 14);
      height: em(140, 14);
      background-position: em(-180, 14) em(-84, 14);
    }
    // Background
    &::after {
      content: ' ';
      position: absolute;
      top: auto;
      right: em(20, 14);
      bottom: em(-91, 14);
      width: em(238, 14);
      height: em(154, 14);
      background: url('../images/news.svg') no-repeat 0 0 / contain;
    }
    span {
      margin-bottom: em(12, 14);
      font-size: em(14, 14);
    }
    // Button
    a {
      padding: em(8, 14) 0 em(9, 14);
      width: em(238, 14);
    }
  }
}

// --- Desktop --- //

@include mq($from: desktop) {
  // Main title
  .news section > h1::before {
    right: 20px;
    width: em(298, 40);
    background-position: em(-308, 40) em(-850, 40);
  }
  // Call to action's background
  .article::after,
  .news::after {
    // #footer's hgt & mt + #customer's hgt & mt + #sponsorship's hgt + #cta's hgt + 70px overflow
    height: em(1247, 18);
    background-image: url('../images/news.svg');
    background-repeat: no-repeat;
    // 50% + #cta's half width - background's half width + 80px overflow
    background-position: calc(50% + 600px - 181px + 80px) em(126, 18);
  }
  // Main content's header
  .inner-wrapper > header {
    margin-top: em(64, 18);
    border-bottom-width: em(10, 18);
    padding: 0 em(30, 18) em(32, 18);
    div {
      padding-right: em(30, 18);
      width: em(740, 18);
    }
  }
  // Talks
  .presentation {
    .organizer,
    .sponsor {
      padding: em(18, 18);
      // Right corner
      &::before {
        width: em(252, 18);
      }
    }
  }
  .news .presentation {
    margin: em(-14, 18) em(15, 18) 0;
    li {
      margin-top: 0;
      margin-bottom: 0;
      padding: em(14, 18) em(15, 18);
      &:last-child {
        margin-bottom: em(42, 18);
      }
    }
  }
  // Ribbon
  .news .ribbon {
    top: -8px;
    right: -8px;
    font-size: rem(15);
    line-height: lh(30, 15);
  }
  // Call to action
  .article .cta,
  .news .cta {
    padding: em(98, 18) 0 em(56, 18);
    // Strip
    &::before {
      top: 3px;
      left: em(126, 18);
      width: em(72, 18);
      height: em(180, 18);
      background-position: em(-324, 18) 0;
    }
    span {
      margin-bottom: em(14, 18);
    }
    // Button
    a {
      padding: em(10, 18) 0;
      width: em(306, 18);
    }
  }
}

.inner-wrapper .goodbye {
  margin: 0 2em 2em;
  padding: 0.5em;
  background: #ffeecc;
  color: #f05a22;
  border: 0.1rem solid #f05a22;

  h3 {
    margin-bottom: 0.25em;
  }
}
