// -------------- //
// --- FOOTER --- //
// -------------- //

#footer {
  color: $white;
  background-color: $orange;

  .inner-wrapper {
    padding: 1rem 1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $orange;
    color: $white;
  }

  ul,
  > p {
    margin: 0;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;

    list-style: none;
    padding: 0;

    li {
      margin: 5px;
      flex: 1 1 33.33333%;
    }
  }

  // Copyright
  small {
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
    border-top: 1px solid $light-beige;
    text-align: center;
    font-size: 10pt;
  }

  .c-footer--infos {
    display: flex;
    gap: 3rem;
    place-items: center;
    flex-wrap: nowrap;
  }

  .c-footer--links {
    flex-wrap: wrap;

    a {
      color: $white;
      text-decoration: none;

      &:focus,
      &:hover,
      &:active {
        text-decoration: underline;
      }
    }
  }

  .c-footer--socialmedia {
    justify-content: center;
    gap: 1rem;
    li {
      flex: 0;
    }
  }

  .c-icon {
    fill: $dark-grey;
  }

  a:hover,
  a:focus {
    .c-icon {
      fill: $dark-blue;
    }
  }
}

a.c-footer--newsletter-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 13rem;

  margin-bottom: 1rem;
  padding: 0.5rem;

  background: white;
  border-radius: 4px;
  text-align: center;

  text-decoration: none;
  color: #1eb9d7;
  font-size: 0.8rem;

  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    background: $light-beige;
  }

  img {
    max-height: 3rem;
  }

  strong {
    margin: 0.5rem 0 0;
    font-size: 1rem;
  }
}

.qualiopi {
  font-size: 90%;
  font-family: $regular;
  text-align: center;

  img {
    height: 5rem;
    display: block;
    margin: 0 auto 1rem;
  }
}

// --- Mobile --- //

@include mq($until: tablet) {
  #footer {
    .c-footer--infos {
      display: block;
      text-align: center;
    }
    ul {
      width: 100%;
      padding-bottom: 1rem;
      margin-bottom: 1rem;
      border-bottom: 1px solid $light-beige;
      text-align: center;
    }
    #datadock {
      width: 50%;
    }
  }
}
