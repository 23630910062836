// ------------- //
// --- ABOUT --- //
// ------------- //

.staff-wrapper {
  img {
    display: block;
    border-bottom-style: solid;
    border-bottom-color: $blue;
    height: auto;
    width: auto;
    max-width: 100%;
  }
  h3 {
    text-align: center;
  }
}

// --- Mobile --- //

@include mq($until: tablet) {
  .staff-wrapper {
    padding-right: em(20, 12);
    padding-left: em(20, 12);
    img {
      border-bottom-width: em(6, 12);
    }
    h3 {
      margin: em(20, 15) 0;
    }
    .maxime {
      margin-top: em(40, 12);
    }
  }
}

// --- Tablet & Desktop --- //

@include mq($from: tablet) {
  // Main title
  .about section > h1::before {
    top: 0;
    right: 0;
  }
  .staff-wrapper {
    display: table;
    width: 100%;
    > div {
      display: table-cell;
      box-sizing: border-box;
      width: 50%;
    }
  }
}

// --- Tablet --- //

@include mq(tablet, desktop) {
  // Main title
  .about section > h1::before {
    width: em(214, 30);
    height: em(77, 30);
    background-position: em(-619, 30) em(-778, 30);
  }
  .staff-wrapper {
    margin-top: em(-28, 14);
    > div {
      padding-right: em(20, 14);
      padding-left: em(20, 14);
    }
    img {
      border-bottom-width: em(7, 14);
    }
    h3 {
      margin: em(24, 17) 0;
    }
  }
}

// --- Desktop --- //

@include mq($from: desktop) {
  // Main title
  .about section > h1::before {
    width: em(280, 40);
    height: em(102, 40);
    background-position: em(-616, 40) em(-674, 40);
  }
  .staff-wrapper {
    margin-top: em(-31, 18);
    > div {
      padding-right: em(30, 18);
      padding-left: em(30, 18);
    }
    img {
      border-bottom-width: em(10, 18);
    }
    h3 {
      margin: em(28, 22) 0;
    }
  }
}
