// --------------- //
// --- GENERIC --- //
// --------------- //

@use 'sass:math';

$sprite: url('../images/sprite.svg');
$link-states: '&:focus, &:hover, &:active';

// -------------- //
// --- COLORS --- //
// -------------- //

$brown: #85734a;
$white: #fff;
$dark-white: #fbfaf9;
$blue: #1eb9d7;
$dark-blue: #188a9d;
$orange: #f15a22;
$light-orange: #f6b361;
$dark-orange: #d8511f;
$light-beige: #f8f6f2;
$beige: #bbb4a8;
$dark-beige: #eae4db;
$light-grey: #948f84;
$grey: #bdbdbc;
$dark-grey: #2f2611;

$js-yellow: #e9d21f;
$git-red: #f05033;
$react-blue: #1eb9d7; // #00d1f7;

// ----------------- //
// --- FONT-FACE --- //
// ----------------- //

@font-face {
  font-family: 'open_sanslight';
  src: url('../fonts/opensans-light.woff2') format('woff2'),
    url('../fonts/opensans-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_regular';
  src: url('../fonts/opensans-regular.woff2') format('woff2'),
    url('../fonts/opensans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sanssemibold';
  src: url('../fonts/opensans-semibold.woff2') format('woff2'),
    url('../fonts/opensans-semibold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'open_extrabold';
  src: url('../fonts/opensans-extrabold.woff2') format('woff2'),
    url('../fonts/opensans-extrabold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

$regular: open_sanslight, Arial, Helvetica, sans-serif;
$bold: open_sanssemibold, Arial, Helvetica, sans-serif;
$extrabold: open_extrabold, Arial, Helvetica, sans-serif;

// ----------------- //
// --- FUNCTIONS --- //
// ----------------- //

$base-font-size: 16;

@function lh($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return #{math.div($target, $context)};
}

@function em($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return #{math.div($target, $context)}em;
}

@function rem($target, $context: $base-font-size) {
  @if $target == 0 {
    @return 0;
  }
  @return #{math.div($target, $context)}rem;
}

// ------------- //
// --- MIXIN --- //
// ------------- //

// Add sprite background to pseudo-element
// $inner-tag:      element to display against background image
// $has-position:   is the container already positioned ?
// $pseudo-element: before or after

@mixin sprite-background($inner-tag, $has-position, $pseudo-element: before) {
  @if $has-position == false {
    position: relative;
  }
  &::#{$pseudo-element} {
    content: ' ';
    position: absolute;
    background-image: $sprite;
    background-repeat: no-repeat;
  }
  @if $inner-tag != false {
    #{$inner-tag} {
      position: relative;
    }
  }
}

// ------------------- //
// --- BREAKPOINTS --- //
// ------------------- //

$mq-media-type: screen;

$mq-breakpoints: (
  mobile: 320px,
  tablet: 768px,
  tablet_middle_boundary: 950px,
  tablet_upper_boundary: 1045px,
  desktop: 1240px,
  wide: 1420px,
);

@import 'sass-mq/mq';
