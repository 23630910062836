// ---------------------------
// pagination
// ---------------------------

ol.c-pagination,
.ais-Pagination-list {
  list-style: none;

  // Force global margin override (see _typo.scss)
  padding: 30px 0;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.c-pagination__item,
.ais-Pagination-item {
  display: block;
  color: $dark-grey;
  padding: 1rem;
}

.c-pagination__link,
.ais-Pagination-link {
  &:not(.is-disabled) {
    display: block;
    padding: 0 0.2rem;
    border-radius: 5px;
    color: $dark-grey;
    text-decoration: none;

    &:hover {
      color: $dark-orange;
      text-decoration: underline;
    }
  }
  &.is-disabled,
  &.ais-Pagination-link--selected {
    color: $white;
    background-color: $blue;
    cursor: not-allowed;
    border-radius: 50%;
    min-width: 20px;
    text-align: center;

    &:hover {
      color: $white;
      background-color: $beige;
      text-decoration: none;
    }
  }
}

.ais-Pagination-item--disabled {
  display: none;
}
